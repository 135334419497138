import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

import { styles } from "../AssessmentFormStyle";

import {
  getAssessmentForm,
  updateSectionA,
} from "../../../4.Store/AssessmentSlice";

const SectionA = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [hr_id, setHr_id] = useState("20240319-E64B53");
  const [tickQualification, setTickQualification] = useState(true);
  const [tickSuitability, setTickSuitability] = useState(true);
  const [tickExperience, setTickExperience] = useState(true);
  const [tickFreshGraduate, setTickFreshGraduate] = useState(true);
  const [tickProAffiliate, setTickProAffiliate] = useState(true);

  const { sectionA, interviewDate, interviewTime, interviewAmPm } = useSelector(
    (state) => state.Assessment
  );
  const {
    qualification,
    qualification_suitability,
    experience_relevance,
    working_years,
    fresh_graduate_check,
    professional_affiliate_check,
    professional_affiliate_description,
    remarks,
  } = sectionA;
  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { name, position_applied, fresh_graduate } = sectionOne;

  useEffect(() => {
    dispatch(getAssessmentForm(param._id)).then((data) => {
      // console.log(data);
      setHr_id(data.payload.jobapplication.hr_id);
    });
  }, [param._id]);

  const handleTickQualification = (value) => {
    if (tickQualification) {
      setTickQualification(false);
      const entity = { ...sectionA, qualification: value };
      dispatch(updateSectionA(entity));
    } else {
      setTickQualification(true);
      const entity = { ...sectionA, qualification: "" };
      dispatch(updateSectionA(entity));
    }
  };

  const handleTickSuitability = (value) => {
    if (tickSuitability) {
      setTickSuitability(false);
      const entity = { ...sectionA, qualification_suitability: value };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    } else {
      setTickSuitability(true);
      const entity = { ...sectionA, qualification_suitability: "" };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    }
  };

  const handleTickExperience = (value) => {
    if (tickExperience) {
      setTickExperience(false);
      const entity = { ...sectionA, experience_relevance: value };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    } else {
      setTickExperience(true);
      const entity = { ...sectionA, experience_relevance: "" };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    }
  };

  const handleChangeSectionA = (e) => {
    const entity = { ...sectionA, [e.target.name]: e.target.value };
    dispatch(updateSectionA(entity));
    // console.log(entity);
  };

  const handleTickFreshGraduate = () => {
    if (tickFreshGraduate) {
      setTickFreshGraduate(false);
      const entity = { ...sectionA, fresh_graduate: "yes" };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    } else {
      setTickFreshGraduate(true);
      const entity = { ...sectionA, fresh_graduate: "" };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    }
  };

  const handleTickAffiliate = (value) => {
    if (tickProAffiliate) {
      setTickProAffiliate(false);
      const entity = { ...sectionA, professional_affiliate_check: value };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    } else {
      setTickProAffiliate(true);
      const entity = { ...sectionA, professional_affiliate_check: "" };
      dispatch(updateSectionA(entity));
      // console.log(entity);
    }
  };

  return (
    <>
      <Row className="px-2">
        <Col>
          <ul>
            <li>
              <span style={{ textAlign: "left" }}>Applicant ID</span>
              <br />
              <span style={{ textAlign: "left" }}>{hr_id}</span>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <span style={{ textAlign: "left" }}>Interview Details:</span>
              <br />
              <span style={{ textAlign: "left" }}>{interviewDate}</span>
              <br />
              <span style={{ textAlign: "left" }}>
                {interviewTime?.label
                  ? interviewTime?.label + " " + interviewAmPm?.label
                  : interviewTime + " "}
              </span>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <span style={{ textAlign: "left" }}>Candidate Name</span>
              <br />
              <span style={{ textAlign: "left" }}>{name}</span>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <span style={{ textAlign: "left" }}>Position Applied</span>
              <br />
              <span style={{ textAlign: "left" }}>
                {position_applied?.label || position_applied}
              </span>
            </li>
          </ul>
        </Col>
      </Row>

      <Form.Label style={styles.TitleStyle}>
        (A) Qualifications, Experience and Skills (Please Tick Where Applicable)
      </Form.Label>
      <Row className="py-2">
        <Col lg={5} md={12} sm={12}>
          <Form.Label>Qualifications:</Form.Label>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <div>
            <Form.Check
              inline
              type="checkbox"
              label="Certificate"
              value="certificate"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "certificate"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Diploma"
              value="diploma"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "diploma"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Degree"
              value="degree"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "degree"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Master and above"
              value="masterandabove"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "masterandabove"}
            />
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={5} md={12} sm={12}>
          <Form.Label>Qualifications suitable / relevant:</Form.Label>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <div>
            <Form.Check
              inline
              type="checkbox"
              label="Yes"
              value="yes"
              onChange={(e) => handleTickSuitability(e.target.value)}
              checked={qualification_suitability == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="No"
              value="no"
              onChange={(e) => handleTickSuitability(e.target.value)}
              checked={qualification_suitability == "no"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Partial"
              value="partial"
              onChange={(e) => handleTickSuitability(e.target.value)}
              checked={qualification_suitability == "partial"}
            />
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={5} md={12} sm={12}>
          <Form.Label>Experience relevant:</Form.Label>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <div>
            <Form.Check
              inline
              type="checkbox"
              label="Yes"
              value="yes"
              onChange={(e) => handleTickExperience(e.target.value)}
              checked={experience_relevance == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="No"
              value="no"
              onChange={(e) => handleTickExperience(e.target.value)}
              checked={experience_relevance == "no"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Partial"
              value="partial"
              onChange={(e) => handleTickExperience(e.target.value)}
              checked={experience_relevance == "partial"}
            />
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={5} md={12} sm={12}>
          <Form.Label>No. of years of working experience:</Form.Label>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <Row className="mb-2">
            <Col md={6} sm={12}>
              <InputGroup>
                <Form.Control
                  name="working_years"
                  value={working_years}
                  type="text"
                  onChange={handleChangeSectionA}
                />
                <InputGroup.Text>Years</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={6} sm={12}>
              <div>
                {fresh_graduate?.value == "Yes" ? (
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Fresh Graduate"
                    name="fresh_graduate"
                    value={fresh_graduate}
                    checked={fresh_graduate}
                  />
                ) : (
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Fresh Graduate"
                    name="fresh_graduate_check"
                    value="Yes"
                    onChange={handleTickFreshGraduate}
                    checked={fresh_graduate_check == "Yes"}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={5} md={12} sm={12}>
          <Form.Label>Professional Affiliations:</Form.Label>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <Row className="mb-2">
            <Col md={12} sm={12}>
              <div>
                <Form.Check
                  inline
                  type="checkbox"
                  label="Yes"
                  value="yes"
                  onChange={(e) => handleTickAffiliate(e.target.value)}
                  checked={professional_affiliate_check == "yes"}
                />
                <Form.Check
                  inline
                  type="checkbox"
                  label="No"
                  value="no"
                  onChange={(e) => handleTickAffiliate(e.target.value)}
                  checked={professional_affiliate_check == "no"}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={2} sm={12}>
              <Form.Label>If yes, specify:</Form.Label>
            </Col>
            <Col md={10} sm={12}>
              <Form.Control
                name="professional_affiliate_description"
                value={professional_affiliate_description}
                type="text"
                onChange={handleChangeSectionA}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={5} md={12} sm={12}>
          <Form.Label>Remarks:</Form.Label>
        </Col>
        <Col lg={7} md={12} sm={12}>
          <Form.Control
            name="remarks"
            value={remarks}
            type="text"
            as="textarea"
            rows="3"
            onChange={handleChangeSectionA}
          />
        </Col>
      </Row>
    </>
  );
};
export default SectionA;
