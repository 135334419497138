import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Form, Card } from "react-bootstrap";

import { styles } from "../1.Pages/ApplicationForm/ApplicationFormStyle";
import PDFViewer from "../2.Common/PDFViewer";
import {
  FormLabelTextView,
  FormLabelTextViewBack,
  FormLabelTextViewFront,
  ImageView,
  FormLabelTextViewEssay,
  FormLabelTextViewAttachment,
} from "../2.Common/ViewForm";

import { getApplicationForm, getUpload } from "../4.Store/ApplicationFormSlice";

const ApplicationFormViewSite = () => {
  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApplicationForm(param._id));
    // console.log(param._id);
  }, [param._id]);

  const [viewPdf, setViewPdf] = useState(undefined);

  const {
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionEight,
  } = useSelector((state) => state.ApplicationForm);
  const {
    position_applied,
    name,
    identification_certification,
    date_of_birth,
    age,
    gender,
    contact_number,
    current_location,
    current_location_other,
    email,
    expected_salary,
    highest_qualification,
    fresh_graduate,
    fresh_graduate_no,
    address,
    race,
    health,
    place_of_birth,
    weight,
    income_tax_number,
    religion,
    dialect,
    height,
    epf_number,
    image,
    resume,
    cover_letter,
    certificate,
    acknowledgement1,
    internship,
    ads_source,
    ads_source_other,
    relocate,
  } = sectionOne;
  const {
    marital_status,
    children,
    number_of_children,
    mother,
    father,
    spouse,
    emergency_references,
  } = sectionTwo;
  const {
    primaryEduName,
    primaryEduTown,
    primaryEduYear,
    primaryEduQualification,
    primaryEduUploadFile,
    secondaryEduName,
    secondaryEduTown,
    secondaryEduYear,
    secondaryEduQualification,
    secondaryEduUploadFile,
    tertiaryEduName,
    tertiaryEduTown,
    tertiaryEduYear,
    tertiaryEduQualification,
    tertiaryEduUploadFile,
    otherEduName,
    otherEduTown,
    otherEduYear,
    otherEduQualification,
    otherEduUploadFile,
    professional_affiliate,
    computer_skills,
    other_relevant_skills,
    extra_curricular_activities,
    english_written_skill,
    english_oral_skill,
    bahasa_written_skill,
    bahasa_oral_skill,
    mandarin_written_skill,
    mandarin_oral_skill,
    other_written_skill,
    other_oral_skill,
  } = sectionThree;
  const {
    currentEmployer,
    currentPosition,
    currentTelephone,
    currentAddress,
    currentDuties,
    currentReason,
    currentSalary,
    currentFromDate,
    currentToDate,
    reason_for_unemployement,
    relatives,
    relatives_name,
    relatives_position,
    isHavePreviousEmployement,
    isCurrentWorking,
    currentFromMonth,
    currentFromYear,
    currentToMonth,
    currentToYear,
    previous1Employer,
    previous1Position,
    previous1Telephone,
    previous1Address,
    previous1Duties,
    previous1Reason,
    previous1Salary,
    previous1FromDate,
    previous1ToDate,
    previous1FromMonth,
    previous1FromYear,
    previous1ToMonth,
    previous1ToYear,
    previous2Employer,
    previous2Position,
    previous2Telephone,
    previous2Address,
    previous2Duties,
    previous2Reason,
    previous2Salary,
    previous2FromDate,
    previous2ToDate,
    previous2FromMonth,
    previous2FromYear,
    previous2ToMonth,
    previous2ToYear,
  } = sectionFour;
  const { references } = sectionFive;
  const {
    date_to_work,
    enquiry_previos_employer,
    bankrupt,
    arrested_indicted_convicted,
  } = sectionSix;
  const { essay, tellUsHr } = sectionSeven;
  const { acknowledgement2 } = sectionEight;

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  const handleViewPdf = (path, filename) => {
    // console.log(path, filename);
    dispatch(getUpload({ path, filename })).then((result) => {
      // console.log(result.payload);

      const pdf = `data:application/pdf;base64,${result.payload}`;
      setViewPdf(pdf);
    });
  };

  return (
    <>
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Container fluid>
          <Card style={styles.CardStyle} className="px-5 py-2">
            <Card.Header style={styles.CardHeader}>
              <h5 style={styles.NameHeader}>{name}</h5>
            </Card.Header>
            <Card.Body style={styles.CardBody}>
              <Row>
                <Col md={8} sm={6}>
                  <FormLabelTextView
                    label="Position Applied"
                    value={position_applied?.label || position_applied}
                  />
                  <FormLabelTextViewFront
                    label="Expected Salary"
                    value={expected_salary || "N/A"}
                    text="RM"
                  />
                  <FormLabelTextView
                    label="Contact Number | Email"
                    value={contact_number + " " + "|" + " " + email}
                  />
                  <FormLabelTextViewBack
                    label="Age"
                    value={age}
                    text="Years Old"
                  />
                  <FormLabelTextView
                    label="Highest Qualification"
                    value={highest_qualification?.label}
                  />
                  <FormLabelTextView
                    label="Fresh Graduate"
                    value={fresh_graduate?.label || "N/A"}
                  />
                  <FormLabelTextViewBack
                    label="Work Experience"
                    value={fresh_graduate_no || 0}
                    text="Years"
                  />
                </Col>
                <Col md={4} sm={6}>
                  <ImageView src={image} />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <h5 className="px-5" style={styles.TextHeader5}>
            (A) Biodata
          </h5>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="NRIC / Passport No"
                value={identification_certification}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView label="Gender" value={gender?.label} />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView label="Date of Birth" value={date_of_birth} />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Place of Birth"
                value={place_of_birth}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextViewBack label="Weight" value={weight} text="KG" />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextViewBack label="Height" value={height} text="CM" />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Income Tax"
                value={income_tax_number || "N/A"}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="EPF Number"
                value={epf_number || "N/A"}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView label="Race" value={race?.label} />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView label="Dialect" value={dialect?.label} />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView label="Religion" value={religion?.label} />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView label="Address" value={address} />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView label="Health" value={health} />
            </Col>
          </Row>

          <h5 className="px-5" style={styles.TextHeader5}>
            (B) Family
          </h5>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Marital Status"
                value={marital_status?.label}
              />
            </Col>
          </Row>
          {marital_status?.value === "Married" && (
            <>
              <h6 className="px-5" style={styles.TextHeader6}>
                • Spouse Details:
              </h6>
              <Row className="px-5">
                <Col md={4} sm={6}>
                  <FormLabelTextView label="Name" value={spouse?.name} />
                </Col>
                <Col md={4} sm={6}>
                  <FormLabelTextView
                    label="Occupation"
                    value={spouse?.occupation}
                  />
                </Col>
                <Col md={4} sm={6}>
                  <FormLabelTextView
                    label="Employer"
                    value={spouse?.employer}
                  />
                </Col>
              </Row>
            </>
          )}
          {children.length > 0 && (
            <>
              <h6 className="px-5" style={styles.TextHeader6}>
                • Children List:
              </h6>
              <div className="px-5 ">
                <Table className="table-sm">
                  <thead style={styles.TableHeader}>
                    <tr>
                      <td>Children Name</td>
                      <td>Children Age</td>
                    </tr>
                  </thead>
                  {children?.map(({ children_name, children_age }, i) => (
                    <tbody>
                      <tr>
                        <td>{children_name}</td>
                        <td>{children_age}</td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </div>
            </>
          )}
          <h6 className="px-5" style={styles.TextHeader6}>
            • Parents Details:
          </h6>
          <div className="px-5 ">
            <Table className="table-sm">
              <thead style={styles.TableHeader}>
                <tr>
                  <td></td>
                  <td>Father</td>
                  <td>Mother</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{father?.name || "-"}</td>
                  <td>{mother?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Occupation</td>
                  <td>{father?.occupation || "-"}</td>
                  <td>{mother?.occupation || "-"}</td>
                </tr>
                <tr>
                  <td>Employer</td>
                  <td>{father?.employer || "-"}</td>
                  <td>{mother?.employer || "-"}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <h6 className="px-5" style={styles.TextHeader6}>
            • Emergency Contact:
          </h6>
          <div className="px-5 ">
            <Table className="table-sm">
              <thead style={styles.TableHeader}>
                <tr>
                  <td>Name</td>
                  <td>Contact No.</td>
                  <td>Relationship</td>
                </tr>
              </thead>
              {emergency_references?.map(
                ({ name, contact_number, relationship }, i) => (
                  <tbody>
                    <tr>
                      <td>{name}</td>
                      <td>{contact_number}</td>
                      <td>{relationship}</td>
                    </tr>
                  </tbody>
                )
              )}
            </Table>
          </div>

          <h5 className="px-5" style={styles.TextHeader5}>
            (C) Skill, Language & Education
          </h5>
          <h6 className="px-5" style={styles.TextHeader6}>
            • Language Proficiency:
          </h6>
          <div className="px-5 ">
            <Table className="table-sm">
              <thead style={styles.TableHeader}>
                <tr>
                  <td></td>
                  <td>Written Level</td>
                  <td>Spoken Level</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>English</td>
                  <td>{english_written_skill?.label}</td>
                  <td>{english_oral_skill?.label}</td>
                </tr>
                <tr>
                  <td>Bahasa Malaysia</td>
                  <td>{bahasa_written_skill?.label}</td>
                  <td>{bahasa_oral_skill?.label}</td>
                </tr>
                <tr>
                  <td>Mandarin</td>
                  <td>{mandarin_written_skill?.label}</td>
                  <td>{mandarin_oral_skill?.label}</td>
                </tr>
                <tr>
                  <td>Other</td>
                  <td>{other_oral_skill || "-"}</td>
                  <td>{other_written_skill || "-"}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <h6 className="px-5" style={styles.TextHeader6}>
            • Skill:
          </h6>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Professional Affiliate"
                value={professional_affiliate || "None"}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Computer Skill(s)"
                value={computer_skills || "None"}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Other Relevant Skill(s)"
                value={other_relevant_skills || "None"}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Extra Curricular Activities"
                value={extra_curricular_activities || "None"}
              />
            </Col>
          </Row>
          <h6 className="px-5" style={styles.TextHeader6}>
            • Education History:
          </h6>
          <div className="px-5 ">
            <Table className="table-sm">
              <thead style={styles.TableHeader}>
                <tr>
                  <td></td>
                  <td>Institute Name</td>
                  <td>Location</td>
                  <td>Year Attended</td>
                  <td>Qualification</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primary</td>
                  <td>{primaryEduName || "-"}</td>
                  <td>{primaryEduTown || "-"}</td>
                  <td>{primaryEduYear || "-"}</td>
                  <td>{primaryEduQualification || "-"}</td>
                </tr>
                <tr>
                  <td>Secondary</td>
                  <td>{secondaryEduName || "-"}</td>
                  <td>{secondaryEduTown || "-"}</td>
                  <td>{secondaryEduYear || "-"}</td>
                  <td>{secondaryEduQualification || "-"}</td>
                </tr>
                <tr>
                  <td>Tertiary</td>
                  <td>{tertiaryEduName || "-"}</td>
                  <td>{tertiaryEduTown || "-"}</td>
                  <td>{tertiaryEduYear || "-"}</td>
                  <td>{tertiaryEduQualification || "-"}</td>
                </tr>
                <tr>
                  <td>Other</td>
                  <td>{otherEduName || "-"}</td>
                  <td>{otherEduTown || "-"}</td>
                  <td>{otherEduYear || "-"}</td>
                  <td>{otherEduQualification || "-"}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <h5 className="px-5" style={styles.TextHeader5}>
            (D) Work Experience
          </h5>
          <h6 className="px-5" style={styles.TextHeader6}>
            • Current / Most Recent Employment:
          </h6>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Employer Name"
                value={currentEmployer}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Employer Address"
                value={currentAddress}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Position Held"
                value={currentPosition}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Contact Number"
                value={currentTelephone}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextViewFront
                label="Salary"
                value={currentSalary}
                text="RM"
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Still Work Here?"
                value={isCurrentWorking}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="From"
                value={
                  currentFromDate ||
                  currentFromMonth?.label + " " + currentFromYear
                }
              />
            </Col>
            {isCurrentWorking == "No" && (
              <Col md={6} sm={6}>
                <FormLabelTextView
                  label="To"
                  value={
                    currentToDate || currentToMonth?.label + " " + currentToYear
                  }
                />
              </Col>
            )}
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView label="Duties" value={currentDuties} />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Reason for Leaving"
                value={currentReason}
              />
            </Col>
          </Row>
          <h6 className="px-5" style={styles.TextHeader6}>
            • Previous Employment:
          </h6>
          <Row className="px-5 py-2">
            <Col md={12} sm={12}>
              <FormLabelTextView
                label="Have Previous Employment?"
                value={isHavePreviousEmployement}
              />
            </Col>
          </Row>
          {isHavePreviousEmployement == "Yes" && (
            <div className="px-5 ">
              <Table className="table-sm">
                <thead style={styles.TableHeader}>
                  <tr>
                    <td></td>
                    <td>Employment 1</td>
                    <td>Employment 2</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{previous1Employer}</td>
                    <td>{previous2Employer}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{previous1Address}</td>
                    <td>{previous2Address}</td>
                  </tr>
                  <tr>
                    <td>Position Held</td>
                    <td>{previous1Position}</td>
                    <td>{previous2Position}</td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>{previous1Telephone}</td>
                    <td>{previous2Telephone}</td>
                  </tr>
                  <tr>
                    <td>Salary</td>
                    <td>RM&nbsp;{previous1Salary}</td>
                    <td>RM&nbsp;{previous2Salary}</td>
                  </tr>
                  <tr>
                    <td>Employment Period</td>
                    <td>
                      {previous1FromDate} - {previous1ToDate}
                    </td>
                    <td>
                      {previous2FromDate} - {previous2ToDate}
                    </td>
                  </tr>
                  <tr>
                    <td>Duties</td>
                    <td>{previous1Duties}</td>
                    <td>{previous2Duties}</td>
                  </tr>
                  <tr>
                    <td>Reason Leaving</td>
                    <td>{previous1Reason}</td>
                    <td>{previous2Reason}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {reason_for_unemployement && (
            <Row className="px-5 py-2">
              <Col md={12} sm={12}>
                <FormLabelTextView
                  label="For any period of unemployment, please state reasons"
                  value={reason_for_unemployement}
                />
              </Col>
            </Row>
          )}
          <Row className="px-5 py-2">
            <Col md={12} sm={12}>
              <FormLabelTextView
                label="Do you have any relatives/close working for HSL group OR other construction/property companies?"
                value={relatives}
              />
            </Col>
          </Row>
          {relatives == "Yes" && (
            <Row className="px-5 py-2">
              <Col md={6} sm={12}>
                <FormLabelTextView label="Name" value={relatives_name} />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelTextView
                  label="Position"
                  value={relatives_position}
                />
              </Col>
            </Row>
          )}

          <h5 className="px-5" style={styles.TextHeader5}>
            (E) Reference
          </h5>
          <div className="px-5 ">
            <Table className="table-sm">
              <thead style={styles.TableHeader}>
                <tr>
                  <td>Name</td>
                  <td>Address</td>
                  <td>Contact No.</td>
                  <td>Occupation</td>
                  <td>Relationship</td>
                  <td>Year Known</td>
                </tr>
              </thead>
              {references?.map(
                (
                  {
                    reference_name,
                    reference_address,
                    reference_contactNumber,
                    reference_occupation,
                    reference_relationship,
                    reference_yearsKnown,
                  },
                  i
                ) => (
                  <tbody>
                    <tr>
                      <td>{reference_name}</td>
                      <td>{reference_address}</td>
                      <td>{reference_contactNumber}</td>
                      <td>{reference_occupation}</td>
                      <td>{reference_relationship}</td>
                      <td>{reference_yearsKnown}</td>
                    </tr>
                  </tbody>
                )
              )}
            </Table>
          </div>

          <h5 className="px-5" style={styles.TextHeader5}>
            (F) Other Informations
          </h5>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Date Able To Start Work"
                value={date_to_work}
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Advertisement Source"
                value={ads_source?.label}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Current Location"
                value={
                  current_location?.value == "Others"
                    ? current_location_other
                    : current_location?.label
                }
              />
            </Col>
            <Col md={6} sm={6}>
              <FormLabelTextView
                label="Willing To Relocate"
                value={relocate?.label}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col md={12} sm={12}>
              <FormLabelTextView
                label="May Enquiry Be Made About You To Your Previous Employer?"
                value={enquiry_previos_employer?.label}
              />
            </Col>
            <Col md={12} sm={12}>
              <FormLabelTextView
                label="Are You In Default To Arrears On Repayment Of Any Debts?"
                value={bankrupt?.label}
              />
            </Col>
            <Col md={12} sm={12}>
              <FormLabelTextView
                label="Have You Ever Been Arrested, Indicted Or Convicted?"
                value={arrested_indicted_convicted?.label}
              />
            </Col>
          </Row>

          <h5 className="px-5" style={styles.TextHeader5}>
            (G) Writing Skills
          </h5>
          <Row className="px-5 py-2">
            <Col md={12} sm={12}>
              <FormLabelTextViewEssay
                label="In About 150 Words, Write About Yourself And Your Family."
                value={essay}
              />
            </Col>
            <Col md={12} sm={12}>
              <FormLabelTextViewEssay
                label="In About 100 Words, Tell Us How You Would Be A Great Fit For The
                  Role By Highlighting & Elaborating On Your Past Experiences,
                  Qualifications, As Well As Skills & Areas Of Expertise."
                value={tellUsHr}
              />
            </Col>
          </Row>

          <h5 className="px-5" style={styles.TextHeader5}>
            (H) Attachments
          </h5>
          <Row className="px-5 py-2">
            <Col>
              <FormLabelTextViewAttachment
                label="Cover Letter"
                value={cover_letter}
                handleClick={handleViewPdf}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col>
              <FormLabelTextViewAttachment
                label="Resume"
                value={resume}
                handleClick={handleViewPdf}
              />
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col>
              <Form.Label
                style={{
                  fontSize: "15px",
                  textAlign: "start",
                  display: "flex",
                }}
              >
                Certificate(s)
              </Form.Label>
              {certificate?.length > 0 && (
                <ul>
                  <li>
                    {certificate?.map(({ fileName1, path, fileName2 }, i) => (
                      <li>
                        <Form.Control
                          plaintext
                          readOnly
                          style={{ cursor: "pointer" }}
                          value={fileName1}
                          onClick={() => handleViewPdf(path, fileName2)}
                        />
                      </li>
                    ))}
                  </li>
                </ul>
              )}
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Col>
              <FormLabelTextViewAttachment
                label="Certificate for Primary Education"
                value={primaryEduUploadFile}
                handleClick={handleViewPdf}
              />
            </Col>
            <Col>
              <FormLabelTextViewAttachment
                label="Certificate for Secondary Education"
                value={secondaryEduUploadFile}
                handleClick={handleViewPdf}
              />
            </Col>
            <Col>
              <FormLabelTextViewAttachment
                label="Certificate for Tertiary Education"
                value={tertiaryEduUploadFile}
                handleClick={handleViewPdf}
              />
            </Col>
            <Col>
              <FormLabelTextViewAttachment
                label="Certificate for Other Education"
                value={otherEduUploadFile}
                handleClick={handleViewPdf}
              />
            </Col>
          </Row>

          <Form.Check
            style={{ textAlign: "justify", margin: 20 }}
            label="I declare that the information given in this application is true and complete. I understand that any misleading or false information or wilful omission is sufficient reason for refusal or dismissal from employment. I also understand that as a condition of employment I may have to successfully complete a company sponsored medical examination. I further understand that final confirmation of employment is subject to a check of references, qualifications and facts, as well as a review of my performance during my probation."
            readOnly
            checked={acknowledgement2 == "agree"}
          />
        </Container>
      )}
    </>
  );
};
export default ApplicationFormViewSite;
