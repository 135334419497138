import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";
import moment from "moment";

const namespace = "ApplicationForm";

// Application Form
export const postApplicationForm = createAsyncThunk(
  `${namespace}/postApplicationForm`,
  async (body, { rejectWithValue }) => {
    const api = "CreateApplication";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getApplicationForm = createAsyncThunk(
  `${namespace}/getApplicationForm`,
  async (body, { rejectWithValue }) => {
    const api = "GetApplication";
    const replace = {
      search: "id",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const putApplicationForm = createAsyncThunk(
  `${namespace}/putApplicationForm`,
  async (body, { rejectWithValue }) => {
    const api = "UpdateApplication";
    const replace = {
      search: "id",
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Email Application Form
export const postThankyouEmail = createAsyncThunk(
  `${namespace}/postThankyouEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_ThankyouEmail_Candidate";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postUpdateEmail = createAsyncThunk(
  `${namespace}/postUpdateEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_UpdateEmail_Candidate";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postHrNotificationEmail = createAsyncThunk(
  `${namespace}/postHrNotificationEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NotificationNewApplicationEmail_HR";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postHrNotificationUpdateJobApplicationEmail = createAsyncThunk(
  `${namespace}/postHrNotificationUpdateJobApplicationEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NotificationUpdateApplicationEmail_HR";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postRejectInternship = createAsyncThunk(
  `${namespace}/postRejectInternship`,
  async (body, { rejectWithValue }) => {
    const api = "Create_RejectInternshipEmail_Candidate";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Upload
export const postUpload = createAsyncThunk(
  `${namespace}/postUpload`,
  async (body, { rejectWithValue }) => {
    const api = "UploadFile";
    const opt = {
      body: body,
    };
    // console.log(body);
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUpload = createAsyncThunk(
  `${namespace}/getUpload`,
  async (body, { rejectWithValue }) => {
    const api = "GetUploadFile";

    // console.log(body);

    const replace = {
      search: "path",
      replace: body.path,

      search2: "fileName2",
      replace2: body.filename,
    };

    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Job Vacancy
export const getVacancy = createAsyncThunk(
  `${namespace}/getVacancy`,
  async (body, { rejectWithValue }) => {
    const api = "GetVacancyList";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  _id: "",
  status: 0,
  finalStatus: "",
  sectionOne: {
    position_applied: "",
    name: "",
    identification_certification: "",
    date_of_birth: "",
    age: "",
    gender: "",
    contact_number: "",
    current_location: "",
    current_location_other: "",
    email: "",
    expected_salary: "",
    highest_qualification: "",
    fresh_graduate: "",
    fresh_graduate_no: "",
    address: "",
    race: "",
    health: "",
    place_of_birth: "",
    weight: "",
    sosco_number: "",
    income_tax_number: "",
    religion: "",
    dialect: "",
    height: "",
    epf_number: "",
    image: "",
    resume: "",
    cover_letter: "",
    certificate: [],
    acknowledgement1: "",
    internship: "no",
    ads_source: "",
    ads_source_other: "",
    relocate: "",
    //INTERNSHIP
    institutionName: "",
    studyField: "",
    studyField_other: "",
    internshipStartDate: "",
    internshipFinishDate: "",
    internshipPeriod: "",
    internshipPeriodWeek: "",
    internshipPeriodMonth: "",
    continueWorkAfterInternship: "",
  },
  sectionTwo: {
    marital_status: "",
    children: [],
    number_of_children: 0,

    mother: {
      name: "",
      occupation: "",
      employer: "",
    },
    father: {
      name: "",
      occupation: "",
      employer: "",
    },
    spouse: {
      name: "",
      occupation: "",
      employer: "",
    },
    emergency_references: [],
  },
  sectionThree: {
    primaryEduName: "",
    primaryEduTown: "",
    primaryEduYear: "",
    primaryEduQualification: "",
    primaryEduUploadFile: "",

    secondaryEduName: "",
    secondaryEduTown: "",
    secondaryEduYear: "",
    secondaryEduQualification: "",
    secondaryEduUploadFile: "",

    tertiaryEduName: "",
    tertiaryEduTown: "",
    tertiaryEduYear: "",
    tertiaryEduQualification: "",
    tertiaryEduUploadFile: "",

    otherEduName: "",
    otherEduTown: "",
    otherEduYear: "",
    otherEduQualification: "",
    otherEduUploadFile: "",

    professional_affiliate: "",
    computer_skills: "",
    other_relevant_skills: "",
    extra_curricular_activities: "",

    english_written_skill: "",
    english_oral_skill: "",
    bahasa_written_skill: "",
    bahasa_oral_skill: "",
    mandarin_written_skill: "",
    mandarin_oral_skill: "",

    // other_language: [],
    other_written_skill: "",
    other_oral_skill: "",

    // edu_histories: [],
  },
  sectionFour: {
    currentEmployer: "",
    currentPosition: "",
    currentTelephone: "",
    currentAddress: "",
    currentDuties: "",
    currentReason: "",
    currentSalary: "",
    currentFromDate: "",
    currentToDate: "",
    // working_experiences: [],
    reason_for_unemployement: "",
    relatives: "",
    relatives_name: "",
    relatives_position: "",
    isHavePreviousEmployement: "",
    isCurrentWorking: "",
    currentFromMonth: "",
    currentFromYear: "",
    currentToMonth: "",
    currentToYear: "",

    previous1Employer: "",
    previous1Position: "",
    previous1Telephone: "",
    previous1Address: "",
    previous1Duties: "",
    previous1Reason: "",
    previous1Salary: "",
    previous1FromDate: "",
    previous1ToDate: "",
    previous1FromMonth: "",
    previous1FromYear: "",
    previous1ToMonth: "",
    previous1ToYear: "",

    previous2Employer: "",
    previous2Position: "",
    previous2Telephone: "",
    previous2Address: "",
    previous2Duties: "",
    previous2Reason: "",
    previous2Salary: "",
    previous2FromDate: "",
    previous2ToDate: "",
    previous2FromMonth: "",
    previous2FromYear: "",
    previous2ToMonth: "",
    previous2ToYear: "",
  },
  sectionFive: {
    references: [],
  },
  sectionSix: {
    date_to_work: "",
    enquiry_previos_employer: "",
    bankrupt: "",
    arrested_indicted_convicted: "",
  },
  sectionSeven: {
    essay: "",
    tellUsHr: "",
    essayWordCount: 0,
    tellUsHrWordCount: 0,
  },
  sectionEight: {
    acknowledgement2: "",
  },
  loading: false,
  error: false,
  currentTab: 0,
  emailSend: 0,
  isRead: 0,
  validationMessage: "",
  circulation: [],
  hrRemark: "",
  currentListTab: 0,
  nonSarawakian: "",
  foreigner: "",
  isUpdatedByCandidate: 0,
  currentSvTab: 0,
  date: moment().format("yyyy-MM-DD, h:mm:ss a"),
  date2: moment().format("yyyy-MM-DD"),
  dateUpdated: moment().format("yyyy-MM-DD, h:mm:ss a"),
  dateUpdated2: moment().format("yyyy-MM-DD"),
  dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
  dateUpdatedStatus2: moment().format("yyyy-MM-DD"),
  dateSendEmailForFullForm: moment().format("yyyy-MM-DD, h:mm:ss a"),
  dateSendEmailForFullForm2: moment().format("yyyy-MM-DD"),
  dateSendEmailForRejectInternship: moment().format("yyyy-MM-DD, h:mm:ss a"),
  dateSendEmailForRejectInternship2: moment().format("yyyy-MM-DD"),
};

const ApplicationFormSlice = createSlice({
  name: "Application Form",
  initialState,
  reducers: {
    resetApplicationForm(state, action) {
      return {
        ...state,
        sectionOne: initialState.sectionOne,
        sectionTwo: initialState.sectionTwo,
        sectionThree: initialState.sectionThree,
        sectionFour: initialState.sectionFour,
        sectionFive: initialState.sectionFive,
        sectionSix: initialState.sectionSix,
        sectionSeven: initialState.sectionSeven,
        sectionEight: initialState.sectionEight,
      };
    },
    updateSectionOne(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        sectionOne: action.payload,
      };

      return a;
    },
    updateSectionTwo(state, action) {
      const a = {
        ...state,
        sectionTwo: action.payload,
      };

      return a;
    },
    updateSectionThree(state, action) {
      const a = {
        ...state,
        sectionThree: action.payload,
      };

      return a;
    },
    updateSectionFour(state, action) {
      const a = {
        ...state,
        sectionFour: action.payload,
      };

      return a;
    },
    updateSectionFive(state, action) {
      const a = {
        ...state,
        sectionFive: action.payload,
      };

      return a;
    },
    updateSectionSix(state, action) {
      const a = {
        ...state,
        sectionSix: action.payload,
      };

      return a;
    },
    updateSectionSeven(state, action) {
      const a = {
        ...state,
        sectionSeven: action.payload,
      };

      return a;
    },
    updateSectionEight(state, action) {
      const a = {
        ...state,
        sectionEight: action.payload,
      };

      return a;
    },
    updateStatus(state, action) {
      const a = {
        ...state,
        status: action.payload,
      };

      return a;
    },
    updateCurrentTab(state, action) {
      const a = {
        ...state,
        currentTab: action.payload,
      };

      return a;
    },
    updateEmailSend(state, action) {
      const a = {
        ...state,
        emailSend: action.payload,
      };

      return a;
    },
    updateValidationMessage(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        validationMessage: action.payload,
      };

      return a;
    },
    updateIsread(state, action) {
      const a = {
        ...state,
        isRead: action.payload,
      };

      return a;
    },
    updateHrFeedback(state, action) {
      const a = {
        ...state,
        hrRemark: action.payload.hrRemark,
      };
      return a;
    },
    updateCurrentListTab(state, action) {
      const a = {
        ...state,
        currentListTab: action.payload,
      };

      return a;
    },
    updateNonSarawakian(state, action) {
      const a = {
        ...state,
        nonSarawakian: action.payload.nonSarawakian,
      };
      return a;
    },
    updateForeigner(state, action) {
      const a = {
        ...state,
        foreigner: action.payload.foreigner,
      };
      return a;
    },
    updateIsUpdatedByCandidate(state, action) {
      const a = {
        ...state,
        isUpdatedByCandidate: action.payload,
      };

      return a;
    },
    updateCurrentSVTab(state, action) {
      const a = {
        ...state,
        currentSvTab: action.payload,
      };

      return a;
    },
  },
  extraReducers: {
    [postApplicationForm.pending]: (state, action) => {
      state.loading = true;
    },
    [postApplicationForm.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postApplicationForm.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [getApplicationForm.pending]: (state, action) => {
      state.loading = true;
    },
    [getApplicationForm.fulfilled]: (state, action) => {
      state.loading = false;
      state._id = action.payload._id;
      state.status = action.payload.status;
      state.finalStatus = action.payload.finalStatus;
      state.emailSend = action.payload.emailSend;
      state.circulation = action.payload.circulation;
      state.isRead = action.payload.isRead;
      state.hrRemark = action.payload.hrRemark;
      state.nonSarawakian = action.payload.nonSarawakian;
      state.foreigner = action.payload.foreigner;
      state.isUpdatedByCandidate = action.payload.isUpdatedByCandidate;
      state.dateUpdatedStatus = action.payload.dateUpdatedStatus;
      state.dateSendEmailForFullForm = action.payload.dateSendEmailForFullForm;
      state.dateSendEmailForRejectInternship = action.payload.dateSendEmailForRejectInternship;
      state.sectionOne = { ...state.sectionOne, ...action.payload.sectionOne };
      state.sectionTwo = { ...state.sectionTwo, ...action.payload.sectionTwo };
      state.sectionThree = {
        ...state.sectionThree,
        ...action.payload.sectionThree,
      };
      state.sectionFour = {
        ...state.sectionFour,
        ...action.payload.sectionFour,
      };
      state.sectionFive = {
        ...state.sectionFive,
        ...action.payload.sectionFive,
      };
      state.sectionSix = { ...state.sectionSix, ...action.payload.sectionSix };
      state.sectionSeven = {
        ...state.sectionSeven,
        ...action.payload.sectionSeven,
      };
      state.sectionEight = {
        ...state.sectionEight,
        ...action.payload.sectionEight,
      };
    },
    [getApplicationForm.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [putApplicationForm.pending]: (state, action) => {
      state.loading = true;
    },
    [putApplicationForm.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [putApplicationForm.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postThankyouEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postThankyouEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postThankyouEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postUpdateEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postUpdateEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postUpdateEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postHrNotificationEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postHrNotificationEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postHrNotificationEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postHrNotificationUpdateJobApplicationEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postHrNotificationUpdateJobApplicationEmail.fulfilled]: (
      state,
      action
    ) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postHrNotificationUpdateJobApplicationEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postUpload.pending]: (state, action) => {
      state.loading = true;
    },
    [postUpload.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postUpload.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [getUpload.pending]: (state, action) => {
      state.loading = true;
    },
    [getUpload.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [getUpload.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
  },
});

export const {
  resetApplicationForm,
  updateSectionOne,
  updateSectionTwo,
  updateSectionThree,
  updateSectionFour,
  updateSectionFive,
  updateSectionSix,
  updateSectionSeven,
  updateSectionEight,
  updateStatus,
  updateCurrentTab,
  updateEmailSend,
  updateValidationMessage,
  updateIsread,
  updateHrFeedback,
  updateCurrentListTab,
  updateNonSarawakian,
  updateForeigner,
  updateIsUpdatedByCandidate,
  updateCurrentSVTab,
} = ApplicationFormSlice.actions;
export default ApplicationFormSlice.reducer;
