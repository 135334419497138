import React, { useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import PDFViewer from "./PDFViewer.jsx";
import { PreloaderUpload } from "./Preloader.js";
import {
  updateSectionOne,
  postUpload,
  getUpload,
} from "../4.Store/ApplicationFormSlice";

const UploadCertificateInternship = () => {
  const dispatch = useDispatch();

  const [viewPdf, setViewPdf] = useState(undefined);
  const [cert, setCert] = useState();
  const [isUpload, setIsUpload] = useState(false);

  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { certificate } = sectionOne;

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  const handleViewPdf = (path, filename) => {
    // console.log(path, filename);
    dispatch(getUpload({ path, filename })).then((result) => {
      // console.log(result.payload);

      const { Base64 } = require("js-base64");
      const pdf = `data:application/pdf;base64,${result.payload}`;
      setViewPdf(pdf);
    });
  };

  const uploadCertificate = (e) => {
    setIsUpload(true);
    return new Promise((resolve, reject) => {
      const uploadFile = e.target.files[0];
      const fileType = uploadFile.type;
      const fileSize = uploadFile.size;
      const fileTypeAllowed = ["application/pdf"];

      if (!fileTypeAllowed.includes(fileType)) {
        Swal.fire("Accept PDF File Only");
        reject(new Error("Invalid file type"));
        setIsUpload(false);
        e.target.value = null;
        return;
      }

      if (fileSize > 5242880) {
        Swal.fire("File Too Big (>5MB)");
        reject(new Error("File size exceeds the limit"));
        setIsUpload(false);
        e.target.value = null;
        return;
      }

      const form = new FormData();
      form.append("uploadFile", uploadFile);

      dispatch(postUpload(form))
        .then((result) => {
          // console.log(result);
          const details = {
            fileType: uploadFile.type,
            fileSize: uploadFile.size,
            fileName1: uploadFile.name,
            path: result.payload.path,
            fileName2: result.payload.fileName,
          };
          // console.log(details);
          setCert(details);
          resolve(details);
          setIsUpload(false);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
          setIsUpload(false);
        });
    });
  };

  const handleUploadCertificate = (e) => {
    if (!cert) {
      Swal.fire("Choose Certificate File");
    } else {
      // console.log(cert);
      if (cert?.fileName2 && cert?.path) {
        if (cert?.fileSize > 0) {
          // Only proceed if the file size is greater than 0 MB
          let arr = [...certificate];
          // console.log(arr);
          arr.push({ ...cert });
          const entity = { ...sectionOne, certificate: arr };
          dispatch(updateSectionOne(entity));
          // console.log(entity);
          // console.log(certificate);
        } else {
          e.target.value = null;
          Swal.fire("Please Re-Upload Certificate");
        }
      } else {
        e.target.value = null;
        // console.log("Please Re-Upload Certificate Again");
      }

      //   let arr = [...certificate];
      //   arr.push({ ...cert });
      //   const entity = { ...sectionOne, certificate: arr };
      //   dispatch(updateSectionOne(entity));
      //   console.log(entity);
      //   console.log(certificate);
    }
  };

  const handleRemoveCertificate = (index) => {
    Swal.fire({
      title: "Confirm Remove Certificate?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const arr1 = { ...sectionOne };
        const arr2 = arr1.certificate.filter((e, i) => i !== index);
        const entity = {
          ...sectionOne,
          certificate: arr2,
        };
        dispatch(updateSectionOne(entity));
        console.log(entity);
      }
    });
  };

  return (
    <>
      {/* {isUpload && <PreloaderUpload />} */}
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Form.Group as={Row} className="px-2 py-3">
          <Form.Label
            column
            sm="2"
            style={{ fontSize: "15px", textAlign: "left" }}
          >
            Certificate&nbsp;<span className="star">*</span>&nbsp;&nbsp;
            <a id="certificate">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </a>
            <Tooltip anchorSelect="#certificate" content="File limit: 5 MB" />
          </Form.Label>
          <Col sm="10">
            {certificate.length < 3 && (
              <Row>
                <Col md={9} xs={12}>
                  <Form.Control
                    name="certificate"
                    type="file"
                    onChange={uploadCertificate}
                    accept="application/pdf"
                  />
                  <Form.Text muted className="formLabel">
                    Only 3 files allow.
                  </Form.Text>
                </Col>
                <Col md={3} xs={12}>
                  {isUpload ? (
                    <>
                      <div>UPLOADING CERTIFICATE</div>
                      <div className="progress"></div>
                    </>
                  ) : (
                    <Button
                      name="arrCertificate"
                      variant="outline-primary"
                      size="sm"
                      onClick={handleUploadCertificate}
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Save Certificate
                    </Button>
                  )}
                </Col>
              </Row>
            )}
            <Row className="mt-2">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <td>No</td>
                      <td>Certificate File Name</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {certificate?.map(({ fileName1, path, fileName2 }, i) => (
                      <tr key={i}>
                        <td data-column="No:">{i + 1}</td>
                        <td data-column="File Name:">
                          <Form.Control
                            plaintext
                            readOnly
                            value={fileName1}
                            style={{
                              cursor: "pointer",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            onClick={() => handleViewPdf(path, fileName2)}
                          />
                        </td>
                        <td data-column="Action:">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleRemoveCertificate(i)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default UploadCertificateInternship;
