import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";
import moment from "moment";

const namespace = "Circulation";

export const getCirculationByCirculationId = createAsyncThunk(
  `${namespace}/getCirculationByCirculationId`,
  async (body, { rejectWithValue }) => {
    const api = "GetCirculation_CirculationId";
    const replace = {
      search: "circulation_id",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      // console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getCirculationByJobAppId = createAsyncThunk(
  `${namespace}/getCirculationByJobAppId`,
  async (body, { rejectWithValue }) => {
    const api = "GetCirculation_JobappId";
    const replace = {
      search: "jobapp_id",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      response.data.forEach((res) => {
        const dateString = res?.circulationDate?.split(",")[0];
        res.circulationDate = dateString;
      });
      // console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postCirculation = createAsyncThunk(
  `${namespace}/postCirculation`,
  async (body, { rejectWithValue }) => {
    const api = "CreateCirculation";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const putCirculation = createAsyncThunk(
  `${namespace}/putCirculation`,
  async (body, { rejectWithValue }) => {
    const api = "UpdateCirculation";
    const replace = {
      search: "circulation_id",
      // replace: body.id,
      replace: body._id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getInternshipDetailsByJobAppId = createAsyncThunk(
  `${namespace}/getInternshipDetailsByJobAppId`,
  async (body, { rejectWithValue }) => {
    const api = "GetInternshipDetails_JobappId";
    const replace = {
      search: "jobapp_id",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      // console.log(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Email
export const postCirculationSvEmail = createAsyncThunk(
  `${namespace}/postCirculationSvEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_CirculationEmail_Supervisor";
    const replace = {
      search: "circulations_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postHrNotifyAfterCirculationEmail = createAsyncThunk(
  `${namespace}/postHrNotifyAfterCirculationEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NotificationSubmitCirculation_HR";
    const replace = {
      search: "circulation_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSupervisorList = createAsyncThunk(
  `${namespace}/getSupervisorList`,
  async (body, { rejectWithValue }) => {
    const api = "GetSupervisorList";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postContactCandidateForInterviewEmail = createAsyncThunk(
  `${namespace}/postContactCandidateForInterviewEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_ContactCandidateForIVEmail_Candidate";
    const replace = {
      search: "circulation_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postInterviewCancelToSupervisorEmail = createAsyncThunk(
  `${namespace}/postInterviewCancelToSupervisorEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_InterviewCancelEmail_Supervisor";
    const replace = {
      search: "circulaton_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Tidak Digunakan
export const postSvNotifyHREmail = createAsyncThunk(
  `${namespace}/postSvNotifyHREmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NotificationUpdateCirculationEmail_SV";
    const replace = {
      search: "circulations_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postHrNotifyEmail = createAsyncThunk(
  `${namespace}/postHrNotifyEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NotificationContactCandidateEmail_HR";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postHrNotifyAfterSetInterviewerEmail = createAsyncThunk(
  `${namespace}/postHrNotifyAfterSetInterviewerEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NotificationSubmitInterviewer_HR";
    const replace = {
      search: "jobapplication_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  _id: "", //circulation_id
  circulation_id: "",
  jobAppId: "",
  svId: "",
  svName: "",
  svEmail: "",
  svRemark: "",
  ivDate: "",
  ivTime: "",
  ivDuration: "",
  ivMethod: "",
  circulationStatus: "",
  jobapplication: {},
  loading: false,
  error: false,
  circulationList: [],
  sectionHrDept: {
    qualification: "",
    qualification_suitability: "",
    experience_relevance: "",
    working_years: "",
    fresh_graduate_check: "",
    professional_affiliate_check: "",
    professional_affiliate_description: "",
    remarks: "",
  },
  isInterviewerSet: 0,
  isSvRead: 0,
  isContactForIv: 0,
  ivAMPM: "",
  interviewCancelReason: "",
  circulationDate: moment().format("yyyy-MM-DD, h:mm:ss a"),
  circulationDate2: moment().format("yyyy-MM-DD"),
  approvalDate: moment().format("yyyy-MM-DD, h:mm:ss a"),
  approvalDate2: moment().format("yyyy-MM-DD"),
  dateUpdatedCirculationStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
  dateUpdatedCirculationStatus2: moment().format("yyyy-MM-DD"),
  dateSendEmailForInterview: moment().format("yyyy-MM-DD, h:mm:ss a"),
  dateSendEmailForInterview2: moment().format("yyyy-MM-DD"),
  //For internship
  superiorName: "",
  assignDeptProject: "",
};

const CirculationSlice2 = createSlice({
  name: "Circulation",
  initialState,
  reducers: {
    resetSectionHrDept(state, action) {
      return {
        ...state,
        sectionHrDept: initialState.sectionHrDept,
      };
    },
    updateSvId(state, action) {
      const a = {
        ...state,
        svId: action.payload,
      };
      return a;
    },
    updateInterviewDate(state, action) {
      const a = {
        ...state,
        ivDate: action.payload.ivDate,
      };
      return a;
    },
    updateInterviewTime(state, action) {
      const a = {
        ...state,
        ivTime: action.payload.ivTime,
      };
      return a;
    },
    updateInterviewAmPm(state, action) {
      const a = {
        ...state,
        ivAMPM: action.payload.ivAMPM,
      };
      return a;
    },
    updateInterviewMethod(state, action) {
      const a = {
        ...state,
        ivMethod: action.payload.ivMethod,
      };
      return a;
    },
    updateInterviewDuration(state, action) {
      const a = {
        ...state,
        ivDuration: action.payload.ivDuration,
      };
      // console.log(action.payload);
      return a;
    },
    updateSuperiorName(state, action) {
      const a = {
        ...state,
        superiorName: action.payload.superiorName,
      };
      return a;
    },
    updateAssignDeptProject(state, action) {
      const a = {
        ...state,
        assignDeptProject: action.payload.assignDeptProject,
      };
      return a;
    },
    updateHrDept(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        sectionHrDept: action.payload,
      };
      return a;
    },
    updateInteviewCancelReason(state, action) {
      const a = {
        ...state,
        interviewCancelReason: action.payload.interviewCancelReason,
      };
      return a;
    },
    updateSvFeedback(state, action) {
      const a = {
        ...state,
        svRemark: action.payload.svRemark,
      };
      return a;
    },
    //Tidak Digunakan
    updateSvName(state, action) {
      const a = {
        ...state,
        svName: action.payload,
      };
      return a;
    },
    updateSvEmail(state, action) {
      const a = {
        ...state,
        svEmail: action.payload,
      };
      return a;
    },
    updateCirculationStatus(state, action) {
      const a = {
        ...state,
        circulationStatus: action.payload.circulationStatus,
      };
      // console.log(action.payload);
      return a;
    },
  },
  extraReducers: {
    [getCirculationByCirculationId.pending]: (state, action) => {
      state.loading = true;
    },
    [getCirculationByCirculationId.fulfilled]: (state, action) => {
      state.loading = false;

      // console.log(action.payload);

      state._id = action.payload._id;
      state.circulation_i = action.payload.circulation_id;
      state.jobAppId = action.payload.jobAppId;
      // state.svRemark = action.payload.svRemark;
      state.svName = action.payload.svName;
      state.svId = action.payload.svId;
      state.svEmail = action.payload.svEmail;
      state.ivDate = action.payload.ivDate;
      state.ivTime = action.payload.ivTime;
      state.ivDuration = action.payload.ivDuration;
      state.ivMethod = action.payload.ivMethod;
      state.jobapplication = {
        ...state.jobapplication,
        ...action.payload.jobapplication,
      };
      state.circulationStatus = action.payload.circulationStatus;
      state.sectionHrDept = {
        ...state.sectionHrDept,
        ...action.payload.sectionHrDept,
      };
      state.isInterviewerSet = action.payload.isInterviewerSet;
      state.isSvRead = action.payload.isSvRead;
      state.isContactForIv = action.payload.isContactForIv;
      state.ivAMPM = action.payload.ivAMPM;
      // state.superiorName = action.payload.superiorName;
      // state.assignDeptProject = action.payload.assignDeptProject;
      state.interviewCancelReason = action.payload.interviewCancelReason;
      state.dateUpdatedCirculationStatus =
        action.payload.dateUpdatedCirculationStatus;
      state.dateSendEmailForInterview =
        action.payload.dateSendEmailForInterview;
    },
    [getCirculationByCirculationId.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [getCirculationByJobAppId.pending]: (state, action) => {
      state.loading = true;
    },
    [getCirculationByJobAppId.fulfilled]: (state, action) => {
      state.loading = false;
      state.circulationList = [];
      state.circulationList = [...state.circulationList, ...action.payload];
      // console.log(state.circulationList);
    },
    [getCirculationByJobAppId.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postCirculation.pending]: (state, action) => {
      state.loading = true;
    },
    [postCirculation.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postCirculation.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [putCirculation.pending]: (state, action) => {
      state.loading = true;
    },
    [putCirculation.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [putCirculation.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [getInternshipDetailsByJobAppId.pending]: (state, action) => {
      state.loading = true;
    },
    [getInternshipDetailsByJobAppId.fulfilled]: (state, action) => {
      state.loading = false;
      state.svRemark = action.payload.svRemark;
      state.superiorName = action.payload.superiorName;
      state.assignDeptProject = action.payload.assignDeptProject;
      // console.log(state.superiorName, state.assignDeptProject);
    },
    [getInternshipDetailsByJobAppId.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Email
    [postCirculationSvEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postCirculationSvEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postCirculationSvEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postHrNotifyAfterCirculationEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postHrNotifyAfterCirculationEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postHrNotifyAfterCirculationEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postContactCandidateForInterviewEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postContactCandidateForInterviewEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postContactCandidateForInterviewEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postInterviewCancelToSupervisorEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postInterviewCancelToSupervisorEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postInterviewCancelToSupervisorEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Tidak Digunakan
    [postSvNotifyHREmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postSvNotifyHREmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postSvNotifyHREmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postHrNotifyEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postHrNotifyEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postHrNotifyEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postHrNotifyAfterSetInterviewerEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postHrNotifyAfterSetInterviewerEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postHrNotifyAfterSetInterviewerEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
  },
});

export const {
  resetSectionHrDept,
  updateSvId,
  updateInterviewDate,
  updateInterviewTime,
  updateInterviewAmPm,
  updateInterviewMethod,
  updateInterviewDuration,
  updateSuperiorName,
  updateAssignDeptProject,
  updateHrDept,
  updateInteviewCancelReason,
  updateSvFeedback,
} = CirculationSlice2.actions;
export default CirculationSlice2.reducer;
