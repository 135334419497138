import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import { styles } from "../AssessmentFormStyle";
import {
  ConfidenceRateList,
  InterviewRateList,
} from "../../../2.Common/DropdownOptions";

import { updateSectionB } from "../../../4.Store/AssessmentSlice";

const SectionB = () => {
  const dispatch = useDispatch();

  const { sectionB } = useSelector((state) => state.Assessment);
  const {
    confidence_level,
    manner,
    self_esteem,
    dressing,
    overall_impression,
  } = sectionB;

  const handleChangeDropdownConfidence = (value) => {
    const entity = { ...sectionB, confidence_level: value };
    dispatch(updateSectionB(entity));
    // console.log(entity);
  };

  const handleChangeDropdownManner = (value) => {
    const entity = { ...sectionB, manner: value };
    dispatch(updateSectionB(entity));
    // console.log(entity);
  };

  const handleChangeDropdownSelfEsteem = (value) => {
    const entity = { ...sectionB, self_esteem: value };
    dispatch(updateSectionB(entity));
    // console.log(entity);
  };

  const handleChangeDropdownDressing = (value) => {
    const entity = { ...sectionB, dressing: value };
    dispatch(updateSectionB(entity));
    // console.log(entity);
  };

  const handleChangeDropdown = (name, value) => {
    const entity = { ...sectionB, [name]: value };
    switch (name) {
      case "confidence_level":
        dispatch(updateSectionB(entity));
        // console.log(entity);
        break;
      case "manner":
        dispatch(updateSectionB(entity));
        // console.log(entity);
        break;
      case "self_esteem":
        dispatch(updateSectionB(entity));
        // console.log(entity);
        break;
      case "dressing":
        dispatch(updateSectionB(entity));
        // console.log(entity);
        break;
    }
  };

  const handleChangeSectionB = (e) => {
    const entity = { ...sectionB, [e.target.name]: e.target.value };
    dispatch(updateSectionB(entity));
    // console.log(entity);
  };

  return (
    <>
      <Form.Label style={styles.TitleStyle}>
        (B) Image Portrayal and Appearance (Please Choose Where Applicable)
      </Form.Label>

      <Row className="py-2">
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Confidence Level:</Form.Label>
          <Select
            name="confidence_level"
            options={ConfidenceRateList}
            value={confidence_level || ""}
            onChange={handleChangeDropdownConfidence}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Manners and Politeness:</Form.Label>
          <Select
            name="manner"
            options={InterviewRateList}
            value={manner || ""}
            onChange={handleChangeDropdownManner}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Self Esteem:</Form.Label>
          <Select
            name="self_esteem"
            options={InterviewRateList}
            value={self_esteem || ""}
            onChange={handleChangeDropdownSelfEsteem}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Dressing and Grooming:</Form.Label>
          <Select
            name="dressing"
            options={InterviewRateList}
            value={dressing || ""}
            onChange={handleChangeDropdownDressing}
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={12} md={12} sm={12}>
          <Form.Label>Overall Impression:</Form.Label>
          <Form.Control
            name="overall_impression"
            value={overall_impression}
            as="textarea"
            rows="2"
            onChange={handleChangeSectionB}
          />
        </Col>
      </Row>
    </>
  );
};
export default SectionB;
