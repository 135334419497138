import jwt_decode from "jwt-decode";
import {
  Card,
  Container,
  Tab,
  Tabs,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { styles } from "../1.Pages/SVFeedback/SVFeedbackStyle";
import NavbarMenu from "../2.Common/NavbarMenu";
import SectionOne from "../1.Pages/ApplicationForm/SectionOne";
import SectionOneInternship from "../1.Pages/InternshipForm/SectionOneInternship";
import ApplicationFormViewSite from "./ApplicationFormViewSite";
import SVFeedback from "../1.Pages/SVFeedback/SVFeedback";

import {
  updateCurrentTab,
  getApplicationForm,
} from "../4.Store/ApplicationFormSlice";
import {
  getCirculationByCirculationId,
  getInternshipDetailsByJobAppId,
  putCirculation,
} from "../4.Store/CirculationSlice";

const SVFormViewSite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    dispatch(getCirculationByCirculationId(param._id)).then((data) => {
      dispatch(getApplicationForm(data.payload.jobAppId));
      dispatch(getInternshipDetailsByJobAppId(data.payload.jobAppId));
    });
  }, [param._id]);

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);
  const isSV = token?.realm_access?.roles?.includes("jobapp_sv");

  const { currentTab, _id, sectionOne } = useSelector(
    (state) => state.ApplicationForm
  );

  const {
    jobAppId,
    svId,
    svName,
    svEmail,
    svRemark,
    ivDate,
    ivTime,
    ivDuration,
    ivMethod,
    circulationStatus,
    superiorName,
    assignDeptProject,
  } = useSelector((state) => state.Circulation);

  // console.log(circulationStatus);

  const handleClickBack = () => {
    navigate("/SVView");
  };

  const handleClickHome = () => {
    navigate("/Homepage");
  };

  const handleClickSaveFeedback = () => {
    const entity = {
      _id: _id,
      jobAppId,
      svId,
      svName,
      svEmail,
      svRemark,
      ivDate,
      ivTime,
      ivDuration,
      ivMethod,
      circulationStatus,
      superiorName,
      assignDeptProject,
    };
    dispatch(putCirculation(entity))
      .then(() => {
        Swal.fire("Save Successfully");
      })
      .then(() => {
        navigate("/SVView");
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err, "error", "OK");
      });
  };

  return (
    <>
      {isSV && (
        <>
          <NavbarMenu />
          <nav aria-label="breadcrumb" class="px-1">
            <ol class="breadcrumb">
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/Homepage" className="breadcrumbstyle">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/SVView" className="breadcrumbstyle">
                  Main List
                </a>
              </li>
            </ol>
          </nav>
          <Container fluid>
            <Card style={styles.CardContainer}>
              {sectionOne?.place_of_birth === "" ? (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Details">
                    {sectionOne?.internship == "yes" ? (
                      <SectionOneInternship />
                    ) : (
                      <SectionOne />
                    )}
                  </Tab>
                  <Tab eventKey="1" title="Supervisor Feedback">
                    <SVFeedback />
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Details">
                    <ApplicationFormViewSite />
                  </Tab>
                  <Tab eventKey="1" title="Supervisor Feedback">
                    <SVFeedback />
                  </Tab>
                </Tabs>
              )}

              <Row className="mx-3">
                <Col>
                  {sectionOne?.place_of_birth ? (
                    currentTab == 0 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button variant="primary" onClick={handleClickBack}>
                            Previous
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab - 1))
                            }
                          >
                            Previous
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button variant="primary" onClick={handleClickBack}>
                          Previous
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab - 1))
                          }
                        >
                          Previous
                        </Button>
                      </ButtonGroup>
                    </>
                  )}

                  {sectionOne?.place_of_birth ? (
                    currentTab == 1 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={handleClickSaveFeedback}
                          >
                            SUBMIT
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab + 1))
                            }
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab + 1))
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={handleClickSaveFeedback}
                        >
                          SUBMIT
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};
export default SVFormViewSite;
