import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";

import PDFViewer from "./PDFViewer";

import {
  postUpload,
  getUpload,
  updateSectionThree,
} from "../4.Store/ApplicationFormSlice";

const UploadEduPrimaryCert = () => {
  const dispatch = useDispatch();
  const [viewPdf, setViewPdf] = useState(undefined);

  const { sectionThree } = useSelector((state) => state.ApplicationForm);
  const { primaryEduUploadFile } = sectionThree;

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  const handleViewPdf = (path, filename) => {
    // console.log(path, filename);
    dispatch(getUpload({ path, filename })).then((result) => {
      // console.log(result.payload);

      const pdf = `data:application/pdf;base64,${result.payload}`;
      setViewPdf(pdf);
    });
  };

  const upload = (e) => {
    return new Promise((resolve, reject) => {
      const uploadFile = e.target.files[0];
      const fileType = uploadFile.type;
      const fileSize = uploadFile.size;
      const fileTypeAllowed = ["application/pdf"];

      if (!fileTypeAllowed.includes(fileType)) {
        Swal.fire("Accept PDF File Only");
        reject(new Error("Invalid file type"));
        // Clear the file input by resetting its value
        e.target.value = null;
        return;
      }

      if (fileSize > 5242880) {
        Swal.fire("File Too Big (>5MB)");
        reject(new Error("File size exceeds the limit"));
        // Clear the file input by resetting its value
        e.target.value = null;
        return;
      }

      const form = new FormData();
      form.append("uploadFile", uploadFile);

      dispatch(postUpload(form))
        .then((result) => {
          console.log(result);
          const details = {
            fileType: uploadFile.type,
            fileSize: uploadFile.size,
            fileName1: uploadFile.name,
            path: result.payload.path,
            fileName2: result.payload.fileName,
          };
          console.log(details);
          resolve(details);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const handleUploadPrimaryEduFile = (e) => {
    upload(e)
      .then((primaryEduUploadFile) => {
        console.log(primaryEduUploadFile);
        if (primaryEduUploadFile?.fileName2 && primaryEduUploadFile?.path) {
          if (primaryEduUploadFile?.fileSize > 0) {
            const entity = {
              ...sectionThree,
              primaryEduUploadFile: primaryEduUploadFile,
            };
            console.log(entity);
            dispatch(updateSectionThree(entity));
          } else {
            e.target.value = null;
            Swal.fire("Please Re-Upload Education Certificate");
          }
        } else {
          e.target.value = null;
        }
      })
      .catch((error) => {
        e.target.value = null;
      });
  };

  return (
    <>
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Form.Group as={Row} className="px-2 py-3">
          <Form.Label
            column
            sm="4"
            style={{ fontSize: "15px", textAlign: "left" }}
          >
            Upload File (If Any)
          </Form.Label>
          <Col sm="8">
            {primaryEduUploadFile && (
              <ul>
                <li>
                  <Form.Control
                    className="cursor"
                    plaintext
                    readOnly
                    value={primaryEduUploadFile?.fileName1}
                    onClick={() =>
                      handleViewPdf(
                        primaryEduUploadFile?.path,
                        primaryEduUploadFile?.fileName2
                      )
                    }
                  />
                </li>
              </ul>
            )}
            <Form.Control
              name="primaryEduUploadFile"
              onChange={handleUploadPrimaryEduFile}
              type="file"
              accept="application/pdf"
            />
          </Col>
        </Form.Group>
      )}
    </>
  );
};
export default UploadEduPrimaryCert;
