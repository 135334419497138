import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Row, Col } from "react-bootstrap";

import { styles } from "./ApplicationFormStyle";
import {
  FormLabelControlText,
  FormLabelControlTextFull,
  FormLabelControlSalary,
  FormLabelControlTextArea,
  FormLabelCheckRadio,
  FormLabelDateSelector,
} from "../../2.Common/RequiredForm";
import { MonthList } from "../../2.Common/DropdownOptions";
import { FormLabelControlTextNR } from "../../2.Common/NotRequiredForm";

import { updateSectionFour } from "../../4.Store/ApplicationFormSlice";

const SectionFour = () => {
  const dispatch = useDispatch();

  const { sectionFour } = useSelector((state) => state.ApplicationForm);
  const {
    currentEmployer,
    currentPosition,
    currentTelephone,
    currentAddress,
    currentDuties,
    currentReason,
    currentSalary,
    reason_for_unemployement,
    relatives,
    relatives_name,
    relatives_position,
    isHavePreviousEmployement,
    isCurrentWorking,
    previous1Employer,
    previous1Position,
    previous1Telephone,
    previous1Address,
    previous1Duties,
    previous1Reason,
    previous1Salary,
    previous2Employer,
    previous2Position,
    previous2Telephone,
    previous2Address,
    previous2Duties,
    previous2Reason,
    previous2Salary,
    currentFromMonth,
    currentFromYear,
    currentToMonth,
    currentToYear,
    previous1FromMonth,
    previous1FromYear,
    previous1ToMonth,
    previous1ToYear,
    previous2FromMonth,
    previous2FromYear,
    previous2ToMonth,
    previous2ToYear,
  } = sectionFour;

  const handleChangeForm = (e) => {
    e.persist();
    const entity = {
      ...sectionFour,
      [e.target.name]: e.target.value.toUpperCase(),
    };
    dispatch(updateSectionFour(entity));
    // console.log(entity);
  };

  const handleChangeForm2 = (e) => {
    e.persist();
    const entity = { ...sectionFour, [e.target.name]: e.target.value };
    dispatch(updateSectionFour(entity));
    // console.log(entity);
  };

  const handleChangeSalary = (e) => {
    const newValue = parseFloat(e.target.value);
    const newSalary = parseFloat(newValue.toFixed(2));
    if (!isNaN(newValue)) {
      const entity = {
        ...sectionFour,
        [e.target.name]: Math.trunc(newSalary),
      };
      dispatch(updateSectionFour(entity));
      // console.log(entity);
    }
  };

  const handleChangeMonth = (selectedOption) => {
    dispatch(
      updateSectionFour({ ...sectionFour, currentFromMonth: selectedOption })
    );
  };

  const handleMonth = (name, value) => {
    const entity = { ...sectionFour, [name]: value };
    switch (name) {
      case "currentFromMonth":
        dispatch(updateSectionFour(entity));
        // console.log(entity);
        break;
      case "currentToMonth":
        dispatch(updateSectionFour(entity));
        // console.log(entity);
        break;
      case "previous1FromMonth":
        dispatch(updateSectionFour(entity));
        // console.log(entity);
        break;
      case "previous1ToMonth":
        dispatch(updateSectionFour(entity));
        // console.log(entity);
        break;
      case "previous2FromMonth":
        dispatch(updateSectionFour(entity));
        // console.log(entity);
        break;
      case "previous2ToMonth":
        dispatch(updateSectionFour(entity));
        // console.log(entity);
        break;
    }
  };

  return (
    <>
      <Container fluid>
        <Form>
          <h5 className="px-2" style={styles.TextHeader5}>
            (A) Current / Most Recent Employment
          </h5>
          <Row>
            <Col md={6} sm={12}>
              <FormLabelControlText
                label="Employer Name"
                placeholder="Enter here"
                name="currentEmployer"
                value={currentEmployer}
                handleChangeForm={handleChangeForm}
              />
            </Col>
            <Col md={6} sm={12}>
              <FormLabelControlText
                label="Contact No."
                placeholder="Enter here"
                name="currentTelephone"
                value={currentTelephone}
                handleChangeForm={handleChangeForm2}
              />
            </Col>
          </Row>
          <FormLabelControlTextFull
            label="Address"
            placeholder="Enter here"
            name="currentAddress"
            value={currentAddress}
            handleChangeForm={handleChangeForm}
          />
          <Row>
            <Col md={6} sm={12}>
              <FormLabelControlText
                label="Position Held"
                placeholder="Enter here"
                name="currentPosition"
                value={currentPosition}
                handleChangeForm={handleChangeForm}
              />
            </Col>
            <Col md={6} sm={12}>
              <FormLabelControlSalary
                label="Salary"
                placeholder="Enter here"
                inputText="RM"
                name="currentSalary"
                value={currentSalary}
                handleChangeForm={handleChangeSalary}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <FormLabelControlTextArea
                label="Duties"
                placeholder="Enter here"
                rows={10}
                name="currentDuties"
                value={currentDuties}
                handleChangeForm={handleChangeForm2}
              />
            </Col>
            <Col md={6} sm={12}>
              <FormLabelControlTextArea
                label="Reason For Leaving"
                placeholder="Enter here"
                rows={10}
                name="currentReason"
                value={currentReason}
                handleChangeForm={handleChangeForm2}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <FormLabelCheckRadio
                label="Still Work In This Company?"
                name="isCurrentWorking"
                value={isCurrentWorking}
                onChange={handleChangeForm2}
              />
            </Col>
            <Col md={6} sm={12}>
              <Form.Group as={Row} className="px-2 py-3">
                <Form.Label column sm="4" style={styles.FormLabel}>
                  From&nbsp;<span className="star">*</span>
                </Form.Label>
                <Col md={4} sm={12}>
                  <Select
                    name="currentFromMonth"
                    value={currentFromMonth}
                    options={MonthList}
                    onChange={(value) => handleMonth("currentFromMonth", value)}
                    placeholder="Month"
                  />
                </Col>
                <Col md={4} sm={12}>
                  <Form.Control
                    name="currentFromYear"
                    value={currentFromYear}
                    onChange={handleChangeForm}
                    placeholder="Year"
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {isCurrentWorking == "No" && (
            <Row className="justify-content-end">
              <Col md={6} sm={12} className="text-right">
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label column sm="4" style={styles.FormLabel}>
                    To&nbsp;<span className="star">*</span>
                  </Form.Label>
                  <Col md={4} sm={12}>
                    <Select
                      name="currentToMonth"
                      value={currentToMonth}
                      options={MonthList}
                      onChange={(value) => handleMonth("currentToMonth", value)}
                      placeholder="Month"
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Control
                      name="currentToYear"
                      value={currentToYear}
                      onChange={handleChangeForm}
                      placeholder="Year"
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          )}

          <h5 className="px-2" style={styles.TextHeader5}>
            (B) Previous Employment
          </h5>
          <Row>
            <Col md={12} sm={12}>
              <FormLabelCheckRadio
                label="Do You Have Any Previous Employment?"
                name="isHavePreviousEmployement"
                value={isHavePreviousEmployement}
                onChange={handleChangeForm2}
              />
            </Col>
          </Row>
          {isHavePreviousEmployement == "Yes" && (
            <Row>
              <Col md={6} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Employment 1:
                </h6>
                <FormLabelControlTextNR
                  label="Employer Name"
                  placeholder="Enter here"
                  name="previous1Employer"
                  value={previous1Employer}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNR
                  label="Contact No."
                  placeholder="Enter here"
                  name="previous1Telephone"
                  value={previous1Telephone}
                  handleChangeForm={handleChangeForm2}
                />
                <FormLabelControlTextNR
                  label="Address"
                  placeholder="Enter here"
                  name="previous1Address"
                  value={previous1Address}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNR
                  label="Position Held"
                  placeholder="Enter here"
                  name="previous1Position"
                  value={previous1Position}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNR
                  label="Salary"
                  placeholder="Enter here"
                  name="previous1Salary"
                  value={previous1Salary}
                  handleChangeForm={handleChangeSalary}
                />
                <FormLabelControlTextArea
                  label="Duties"
                  rows={5}
                  placeholder="Enter here"
                  name="previous1Duties"
                  value={previous1Duties}
                  handleChangeForm={handleChangeForm2}
                />
                <FormLabelControlTextArea
                  label="Reason For Leaving"
                  rows={5}
                  placeholder="Enter here"
                  name="previous1Reason"
                  value={previous1Reason}
                  handleChangeForm={handleChangeForm2}
                />
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label column sm="4" style={styles.FormLabel}>
                    From Date&nbsp;<span className="star">*</span>
                  </Form.Label>
                  <Col md={4} sm={12}>
                    <Select
                      name="previous1FromMonth"
                      value={previous1FromMonth}
                      options={MonthList}
                      onChange={(value) =>
                        handleMonth("previous1FromMonth", value)
                      }
                      placeholder="Month"
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Control
                      name="previous1FromYear"
                      value={previous1FromYear}
                      onChange={handleChangeForm}
                      placeholder="Year"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label column sm="4" style={styles.FormLabel}>
                    To Date&nbsp;<span className="star">*</span>
                  </Form.Label>
                  <Col md={4} sm={12}>
                    <Select
                      name="previous1ToMonth"
                      value={previous1ToMonth}
                      options={MonthList}
                      onChange={(value) =>
                        handleMonth("previous1ToMonth", value)
                      }
                      placeholder="Month"
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Control
                      name="previous1ToYear"
                      value={previous1ToYear}
                      onChange={handleChangeForm}
                      placeholder="Year"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Employment 2:
                </h6>
                <FormLabelControlTextNR
                  label="Employer Name"
                  placeholder="Enter here"
                  name="previous2Employer"
                  value={previous2Employer}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNR
                  label="Contact No."
                  placeholder="Enter here"
                  name="previous2Telephone"
                  value={previous2Telephone}
                  handleChangeForm={handleChangeForm2}
                />
                <FormLabelControlTextNR
                  label="Address"
                  placeholder="Enter here"
                  name="previous2Address"
                  value={previous2Address}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNR
                  label="Position Held"
                  placeholder="Enter here"
                  name="previous2Position"
                  value={previous2Position}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNR
                  label="Salary"
                  placeholder="Enter here"
                  name="previous2Salary"
                  value={previous2Salary}
                  handleChangeForm={handleChangeSalary}
                />
                <FormLabelControlTextArea
                  label="Duties"
                  rows={5}
                  placeholder="Enter here"
                  name="previous2Duties"
                  value={previous2Duties}
                  handleChangeForm={handleChangeForm2}
                />
                <FormLabelControlTextArea
                  label="Reason For Leaving"
                  rows={5}
                  placeholder="Enter here"
                  name="previous2Reason"
                  value={previous2Reason}
                  handleChangeForm={handleChangeForm2}
                />
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label column sm="4" style={styles.FormLabel}>
                    From Date&nbsp;<span className="star">*</span>
                  </Form.Label>
                  <Col md={4} sm={12}>
                    <Select
                      name="previous2FromMonth"
                      value={previous2FromMonth}
                      options={MonthList}
                      onChange={(value) =>
                        handleMonth("previous2FromMonth", value)
                      }
                      placeholder="Month"
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Control
                      name="previous2FromYear"
                      value={previous2FromYear}
                      onChange={handleChangeForm}
                      placeholder="Year"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label column sm="4" style={styles.FormLabel}>
                    To Date&nbsp;<span className="star">*</span>
                  </Form.Label>
                  <Col md={4} sm={12}>
                    <Select
                      name="previous2ToMonth"
                      value={previous2ToMonth}
                      options={MonthList}
                      onChange={(value) =>
                        handleMonth("previous2ToMonth", value)
                      }
                      placeholder="Month"
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Control
                      name="previous2ToYear"
                      value={previous2ToYear}
                      onChange={handleChangeForm}
                      placeholder="Year"
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          )}

          <FormLabelControlTextNR
            label="For Any Period Of Unemployment, Please State Reason"
            placeholder="Enter Here"
            name="reason_for_unemployement"
            value={reason_for_unemployement}
            handleChangeForm={handleChangeForm}
          />
          <FormLabelCheckRadio
            label="Do You Have Any Relatives/Close Working For HSL Group OR Other Construction/Property Companies?"
            name="relatives"
            value={relatives}
            onChange={handleChangeForm2}
          />
          {relatives == "Yes" && (
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="Name"
                  placeholder="Enter here"
                  name="relatives_name"
                  value={relatives_name}
                  handleChangeForm={handleChangeForm}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="Position"
                  placeholder="Enter here"
                  name="relatives_position"
                  value={relatives_position}
                  handleChangeForm={handleChangeForm}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    </>
  );
};

export default SectionFour;
