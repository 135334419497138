import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";

import { updateHrDept } from "../../../4.Store/CirculationSlice";

const HRDept = () => {
  const dispatch = useDispatch();

  const [tickQualification, setTickQualification] = useState(true);
  const [tickSuitability, setTickSuitability] = useState(true);
  const [tickExperience, setTickExperience] = useState(true);
  const [tickFreshGraduate, setTickFreshGraduate] = useState(true);
  const [tickProAffiliate, setTickProAffiliate] = useState(true);

  const { sectionHrDept } = useSelector((state) => state.Circulation);
  const {
    qualification,
    qualification_suitability,
    experience_relevance,
    fresh_graduate_check,
    professional_affiliate_check,
    professional_affiliate_description,
    remarks,
  } = sectionHrDept;

  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { fresh_graduate, fresh_graduate_no } = sectionOne;

  const handleChangeSectionA = (e) => {
    const entity = { ...sectionHrDept, [e.target.name]: e.target.value };
    dispatch(updateHrDept(entity));
  };

  const handleTickQualification = (value) => {
    if (tickQualification) {
      setTickQualification(false);
      const entity = { ...sectionHrDept, qualification: value };
      dispatch(updateHrDept(entity));
    } else {
      setTickQualification(true);
      const entity = { ...sectionHrDept, qualification: "" };
      dispatch(updateHrDept(entity));
    }
  };

  const handleTickSuitability = (value) => {
    if (tickSuitability) {
      setTickSuitability(false);
      const entity = { ...sectionHrDept, qualification_suitability: value };
      dispatch(updateHrDept(entity));
    } else {
      setTickSuitability(true);
      const entity = { ...sectionHrDept, qualification_suitability: "" };
      dispatch(updateHrDept(entity));
    }
  };

  const handleTickExperience = (value) => {
    if (tickExperience) {
      setTickExperience(false);
      const entity = { ...sectionHrDept, experience_relevance: value };
      dispatch(updateHrDept(entity));
    } else {
      setTickExperience(true);
      const entity = { ...sectionHrDept, experience_relevance: "" };
      dispatch(updateHrDept(entity));
    }
  };

  const handleTickFreshGraduate = (value) => {
    if (tickFreshGraduate) {
      setTickFreshGraduate(false);
      const entity = { ...sectionHrDept, fresh_graduate_check: value };
      dispatch(updateHrDept(entity));
      // console.log(entity);
    } else {
      setTickFreshGraduate(true);
      const entity = { ...sectionHrDept, fresh_graduate_check: "" };
      dispatch(updateHrDept(entity));
      // console.log(entity);
    }
  };

  const handleTickAffiliate = (value) => {
    if (tickProAffiliate) {
      setTickProAffiliate(false);
      const entity = { ...sectionHrDept, professional_affiliate_check: value };
      dispatch(updateHrDept(entity));
      // console.log(entity);
    } else {
      setTickProAffiliate(true);
      const entity = { ...sectionHrDept, professional_affiliate_check: "" };
      dispatch(updateHrDept(entity));
      // console.log(entity);
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Label>Qualifications:</Form.Label>
        </Col>
        <Col md={6}>
          <div>
            <Form.Check
              inline
              type="checkbox"
              label="Certificate"
              value="certificate"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "certificate"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Diploma"
              value="diploma"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "diploma"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Degree"
              value="degree"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "degree"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Master and above"
              value="masterandabove"
              onChange={(e) => handleTickQualification(e.target.value)}
              checked={qualification == "masterandabove"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Label>Qualifications suitable/relevant:</Form.Label>
        </Col>
        <Col md={6}>
          <div>
            <Form.Check
              inline
              type="checkbox"
              label="Yes"
              value="yes"
              onChange={(e) => handleTickSuitability(e.target.value)}
              checked={qualification_suitability == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="No"
              value="no"
              onChange={(e) => handleTickSuitability(e.target.value)}
              checked={qualification_suitability == "no"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Partial"
              value="partial"
              onChange={(e) => handleTickSuitability(e.target.value)}
              checked={qualification_suitability == "partial"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Label>Experience relevant:</Form.Label>
        </Col>
        <Col md={6}>
          <div>
            <Form.Check
              inline
              type="checkbox"
              label="Yes"
              value="yes"
              onChange={(e) => handleTickExperience(e.target.value)}
              checked={experience_relevance == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="No"
              value="no"
              onChange={(e) => handleTickExperience(e.target.value)}
              checked={experience_relevance == "no"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Partial"
              value="partial"
              onChange={(e) => handleTickExperience(e.target.value)}
              checked={experience_relevance == "partial"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Label>No. of years of working experience:</Form.Label>
        </Col>
        <Col md={6}>
          <Row className="mb-2">
            <Col md={6}>
              <InputGroup>
                <Form.Control
                  name="fresh_graduate_no"
                  value={fresh_graduate_no}
                />
                <InputGroup.Text>Years</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={6}>
              <div>
                {fresh_graduate?.value == "Yes" ? (
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Fresh Graduate"
                    name="fresh_graduate"
                    value={fresh_graduate}
                    // onChange={handleTickFreshGraduate}
                    checked={fresh_graduate}
                  />
                ) : (
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Fresh Graduate"
                    name="fresh_graduate_check"
                    value="Yes"
                    onChange={handleTickFreshGraduate}
                    checked={fresh_graduate_check == "Yes"}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Label>Professional Affiliations:</Form.Label>
        </Col>
        <Col md={6}>
          <Row className="mb-2">
            <Col md={12}>
              <div>
                <Form.Check
                  inline
                  type="checkbox"
                  label="Yes"
                  value="yes"
                  onChange={(e) => handleTickAffiliate(e.target.value)}
                  checked={professional_affiliate_check == "yes"}
                />
                <Form.Check
                  inline
                  type="checkbox"
                  label="No"
                  value="no"
                  onChange={(e) => handleTickAffiliate(e.target.value)}
                  checked={professional_affiliate_check == "no"}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={12}>
              <Form.Label>If yes, specify:</Form.Label>
            </Col>
            <Col md={12}>
              <Form.Control
                name="professional_affiliate_description"
                value={professional_affiliate_description}
                type="text"
                onChange={handleChangeSectionA}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Label>Remarks:</Form.Label>
        </Col>
        <Col md={6}>
          <Form.Control
            name="remarks"
            value={remarks}
            type="text"
            as="textarea"
            rows="3"
            onChange={handleChangeSectionA}
          />
        </Col>
      </Row>
    </>
  );
};
export default HRDept;
