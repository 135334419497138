import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Breadcrumb,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBox,
  faGraduationCap,
  faBoltLightning,
  faCircleCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from "moment";

import { styles } from "./HRTableListStyle";
import NavbarMenu from "../../2.Common/NavbarMenu";
import PaginationList from "../../2.Common/Pagination";
import { PreActionList } from "../../2.Common/DropdownOptions";

import {
  getHRDuplicateListSearch,
  getHRDuplicateListPagination,
  updateCurrentDuplicatePage,
  updateSearchDuplicateList,
} from "../../4.Store/HRTableListSlice";
import {
  getApplicationForm,
  putApplicationForm,
} from "../../4.Store/ApplicationFormSlice";

const DuplicateListNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tick, setTick] = useState([]);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);

  const {
    duplicateLists,
    currentDuplicatePage,
    totalCurrentDuplicatePage,
    searchDuplicateLists,
    searchKeyWordDuplicateLists,
  } = useSelector((store) => store.HRTableList);
  const { status } = useSelector((state) => state.ApplicationForm);

  useEffect(() => {
    if (searchButtonClicked) {
      // Only make the API call when the search button is clicked
      if (searchKeyWordDuplicateLists) {
        dispatch(getHRDuplicateListSearch(searchKeyWordDuplicateLists));
      } else {
        dispatch(getHRDuplicateListPagination(currentDuplicatePage));
      }
      setSearchButtonClicked(false); // Reset the search button state
    } else {
      // Load all lists by default
      dispatch(getHRDuplicateListPagination(currentDuplicatePage));
    }
  }, [currentDuplicatePage, searchKeyWordDuplicateLists, searchButtonClicked]);

  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentDuplicatePage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const handleClickBack = () => {
    navigate("/HRView");
  };

  const handleClickHome = () => {
    navigate("/Homepage");
  };

  const handleSearchClick = () => {
    setSearchButtonClicked(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSearchClick(event);
    }
  };

  const handleDropdownPreAction = (value) => {
    tick.forEach((item) => {
      const id = item;
      if (value?.value === 1) {
        dispatch(putApplicationForm({ id: id, status: 1 })).then(() => {
          dispatch(getHRDuplicateListPagination(currentDuplicatePage));
        });
      }
      if (value?.value === 30) {
        dispatch(putApplicationForm({ id: id, status: 30 })).then(() => {
          dispatch(getHRDuplicateListPagination(currentDuplicatePage));
        });
      }
      if (value?.value === 50) {
        dispatch(
          putApplicationForm({
            id: id,
            status: 50,
            dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
          })
        ).then(() => {
          dispatch(getHRDuplicateListPagination(currentDuplicatePage));
        });
      }
      if (value?.value === 100) {
        dispatch(
          putApplicationForm({
            id: id,
            status: 100,
            dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
          })
        ).then(() => {
          dispatch(getHRDuplicateListPagination(currentDuplicatePage));
        });
      }
      if (value?.value == 200) {
        dispatch(
          putApplicationForm({
            id: id,
            status: 200,
            dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
          })
        ).then(() => {
          dispatch(getHRDuplicateListPagination(currentDuplicatePage));
        });
      }
    });

    setTick([]);
  };

  const handlePagination = (page) => {
    dispatch(updateCurrentDuplicatePage(page));
  };

  const handleClick = (_id) => {
    navigate(`/HRViewForm/${_id}`);
  };

  const handleTickCheckBox = (id, checked) => {
    let updatedTick;
    if (checked) {
      updatedTick = [...tick, id];
    } else {
      updatedTick = tick.filter((item) => item !== id); // Remove the id from the array
    }
    setTick(updatedTick);
    // console.log(updatedTick);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/basic/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadApplicationForm = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/full/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <>
      <NavbarMenu />
      <nav aria-label="breadcrumb" class="px-1">
        <ol class="breadcrumb">
          <li class="breadcrumb-item breadcrumb-item">
            <a href="#/Homepage" className="breadcrumbstyle">
              Home
            </a>
          </li>
          <li class="breadcrumb-item breadcrumb-item">
            <a href="#/HRView" className="breadcrumbstyle">
              Main List
            </a>
          </li>
        </ol>
      </nav>
      <Container fluid>
        <Card>
          <h3 className="text-center">Duplicate List</h3>

          <Row className="justify-content-md-center px-3">
            <Col>
              <InputGroup className="mb-3">
                <Form.Control
                  className="form-control"
                  placeholder="Search"
                  onChange={({ currentTarget: input }) => {
                    dispatch(updateSearchDuplicateList(input.value));
                  }}
                  onKeyDown={handleKeyDown}
                  value={searchKeyWordDuplicateLists}
                />
                <Button variant="outline-info" onClick={handleSearchClick}>
                  <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
                </Button>
              </InputGroup>
            </Col>
            <Col md="auto" sm="auto">
              <Select
                className="px-3"
                options={PreActionList}
                value={status}
                onChange={handleDropdownPreAction}
                placeholder="Pre Action"
              />
            </Col>
            <Col md="auto" sm="auto">
              {searchButtonClicked == false ? (
                searchKeyWordDuplicateLists ? (
                  <PaginationList
                    nPages={1}
                    currentPage={1}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <PaginationList
                    nPages={totalCurrentDuplicatePage}
                    currentPage={currentDuplicatePage}
                    handlePagination={handlePagination}
                  />
                )
              ) : (
                <PaginationList
                  nPages={totalCurrentDuplicatePage}
                  currentPage={currentDuplicatePage}
                  handlePagination={handlePagination}
                />
              )}
              {/* <PaginationList
                nPages={totalCurrentDuplicatePage}
                handlePagination={handlePagination}
                currentPage={currentDuplicatePage}
              /> */}
            </Col>
          </Row>

          <Table>
            <thead style={styles.TableHeader}>
              <tr>
                <th style={styles.ThIcon}>
                  <FontAwesomeIcon icon={faBox} />
                </th>
                <th style={styles.ThText}>No</th>
                <th style={styles.ThText}>Date Applied</th>
                <th style={styles.ThText}>Name</th>
                <th style={styles.ThText}>Position</th>
                <th style={styles.ThText}>NRIC</th>
                <th style={styles.ThIcon}>
                  <FontAwesomeIcon icon={faGraduationCap} />
                </th>
                <th style={styles.ThText}>RM</th>
                <th>
                  <FontAwesomeIcon icon={faBoltLightning} />
                </th>
              </tr>
            </thead>
            <tbody>
              {searchKeyWordDuplicateLists ? (
                <>
                  {searchDuplicateLists.map(
                    (
                      { _id, date, sectionOne, hrRemark, isUpdatedByCandidate },
                      i
                    ) => (
                      <tr key={i}>
                        <td style={styles.TdIcon}>
                          <Form.Check
                            type="checkbox"
                            checked={tick.includes(_id)}
                            onClick={(e) =>
                              handleTickCheckBox(_id, e.target.checked)
                            }
                          />
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {i + 1 + indexOfFirstRecord}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {date}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.name.toUpperCase()}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.position_applied?.label ||
                            sectionOne?.position_applied.toUpperCase() ||
                            "INTERNSHIP"}
                        </td>
                        <td
                          style={styles.TdIcon}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.identification_certification}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.highest_qualification?.value == "SPM" &&
                            "SPM"}
                          {sectionOne?.highest_qualification?.value ==
                            "STPM/Matriculation/Foundation/STAM" && "STPM"}
                          {sectionOne?.highest_qualification?.value ==
                            "Certificates" && "CERT"}
                          {sectionOne?.highest_qualification?.value ==
                            "Diploma" && "DIP"}
                          {sectionOne?.highest_qualification?.value ==
                            "Degree" && "DEG"}
                          {sectionOne?.highest_qualification?.value ==
                            "Master" && "MAS"}
                          {sectionOne?.highest_qualification?.value == "PHD" &&
                            "PHD"}
                          {sectionOne?.highest_qualification?.value ==
                            "Others" && "OTH"}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.expected_salary}
                        </td>
                        <td
                          className="text-nowrap"
                          style={{ textAlign: "left" }}
                        >
                          <span
                            style={{
                              ...styles.TdIconStyle,
                              display:
                                isUpdatedByCandidate === 1 ? "inline" : "none",
                            }}
                          >
                            <FontAwesomeIcon
                              onClick={() => handleDownloadApplicationForm(_id)}
                              icon={faDownload}
                              title="Print Form"
                              color="green"
                            />
                          </span>
                          <span
                            style={{
                              ...styles.TdIconStyle,
                              display:
                                isUpdatedByCandidate != 1 ? "inline" : "none",
                            }}
                          >
                            <FontAwesomeIcon
                              onClick={() => handleDownloadBiodata(_id)}
                              icon={faDownload}
                              title="Print Biodata"
                              color="blue"
                            />
                          </span>
                          {hrRemark && (
                            <span style={{ marginLeft: "0.5rem" }}>
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                color="blue"
                                title="HR Remark Exist"
                              />
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </>
              ) : (
                <>
                  {duplicateLists.map(
                    (
                      { _id, date, sectionOne, hrRemark, isUpdatedByCandidate },
                      i
                    ) => (
                      <tr key={i}>
                        <td style={styles.TdIcon}>
                          <Form.Check
                            type="checkbox"
                            checked={tick.includes(_id)}
                            onClick={(e) =>
                              handleTickCheckBox(_id, e.target.checked)
                            }
                          />
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {i + 1 + indexOfFirstRecord}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {date}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.name.toUpperCase()}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.position_applied?.label ||
                            sectionOne?.position_applied.toUpperCase() ||
                            "INTERNSHIP"}
                        </td>
                        <td
                          style={styles.TdIcon}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.identification_certification}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.highest_qualification?.value == "SPM" &&
                            "SPM"}
                          {sectionOne?.highest_qualification?.value ==
                            "STPM/Matriculation/Foundation/STAM" && "STPM"}
                          {sectionOne?.highest_qualification?.value ==
                            "Certificates" && "CERT"}
                          {sectionOne?.highest_qualification?.value ==
                            "Diploma" && "DIP"}
                          {sectionOne?.highest_qualification?.value ==
                            "Degree" && "DEG"}
                          {sectionOne?.highest_qualification?.value ==
                            "Master" && "MAS"}
                          {sectionOne?.highest_qualification?.value == "PHD" &&
                            "PHD"}
                          {sectionOne?.highest_qualification?.value ==
                            "Others" && "OTH"}
                        </td>
                        <td
                          style={styles.TdText}
                          onClick={() => handleClick(_id)}
                        >
                          {sectionOne?.expected_salary}
                        </td>
                        <td
                          className="text-nowrap"
                          style={{ textAlign: "left" }}
                        >
                          <span
                            style={{
                              ...styles.TdIconStyle,
                              display:
                                isUpdatedByCandidate === 1 ? "inline" : "none",
                            }}
                          >
                            <FontAwesomeIcon
                              onClick={() => handleDownloadApplicationForm(_id)}
                              icon={faDownload}
                              title="Print Form"
                              color="green"
                            />
                          </span>
                          <span
                            style={{
                              ...styles.TdIconStyle,
                              display:
                                isUpdatedByCandidate != 1 ? "inline" : "none",
                            }}
                          >
                            <FontAwesomeIcon
                              onClick={() => handleDownloadBiodata(_id)}
                              icon={faDownload}
                              title="Print Biodata"
                              color="blue"
                            />
                          </span>
                          {hrRemark && (
                            <span style={{ marginLeft: "0.5rem" }}>
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                color="blue"
                                title="HR Remark Exist"
                              />
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
            </tbody>
          </Table>

          <Row className="justify-content-md-center px-3">
            <Col>
              <InputGroup className="mb-3">
                <Form.Control
                  className="form-control"
                  placeholder="Search"
                  onChange={({ currentTarget: input }) => {
                    dispatch(updateSearchDuplicateList(input.value));
                  }}
                  onKeyDown={handleKeyDown}
                  value={searchKeyWordDuplicateLists}
                />
                <Button variant="outline-info" onClick={handleSearchClick}>
                  <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
                </Button>
              </InputGroup>
            </Col>
            <Col md="auto" sm="auto">
              <Select
                className="px-3"
                options={PreActionList}
                value={status}
                onChange={handleDropdownPreAction}
                placeholder="Pre Action"
              />
            </Col>
            <Col md="auto" sm="auto">
              {searchButtonClicked == false ? (
                searchKeyWordDuplicateLists ? (
                  <PaginationList
                    nPages={1}
                    currentPage={1}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <PaginationList
                    nPages={totalCurrentDuplicatePage}
                    currentPage={currentDuplicatePage}
                    handlePagination={handlePagination}
                  />
                )
              ) : (
                <PaginationList
                  nPages={totalCurrentDuplicatePage}
                  currentPage={currentDuplicatePage}
                  handlePagination={handlePagination}
                />
              )}
              {/* <PaginationList
                nPages={totalCurrentDuplicatePage}
                handlePagination={handlePagination}
                currentPage={currentDuplicatePage}
              /> */}
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};
export default DuplicateListNew;
