import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFilter,
  faCircleCheck,
  faDownload,
  faCircleXmark,
  faUserSlash,
  faSquareFull,
} from "@fortawesome/free-solid-svg-icons";

import TableInternshipList from "./Components/TableInternshipList";
import PaginationList from "../../2.Common/Pagination";

import {
  getHRInternshipListPagination,
  getHRInternshipListSearch,
  updateSearchInternshipList,
  updateCurrentInternshipPage,
} from "../../4.Store/HRTableListSlice";

const InternshipList = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [positionApplied, setPositionApplied] = useState("");
  const [salaryRangeFrom, setSalaryRangeFrom] = useState(0);
  const [salaryRangeTo, setSalaryRangeTo] = useState(0);

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [source, setSource] = useState("");
  const [statusApplication, setStatusApplication] = useState("");

  const [column, setColumn] = useState("date");
  const [columnDirection, setColumnDirection] = useState(-1);

  const {
    internshipLists,
    currentInternshipPage,
    totalCurrentInternshipPage,
    searchInternshipLists,
    searchKeyWordInternshipLists,
  } = useSelector((store) => store.HRTableList);

  const parseSearchInput = (searchText) => {
    const words = searchText.split(" ");
    const searchObject = {};

    let currentKey = "";
    let keyword = "";

    words.forEach((word) => {
      if (word.includes(":")) {
        const [key, value] = word.split(":");
        const cleanKey = key.trim();
        const cleanValue = value.trim();
        currentKey = cleanKey;
        searchObject[currentKey] = searchObject[currentKey]
          ? searchObject[currentKey] + " " + cleanValue
          : cleanValue;
      } else {
        if (currentKey === "") {
          keyword += word + " ";
        } else {
          searchObject[currentKey] += " " + word;
        }
      }
    });

    // Remove "keyword" key if it's empty
    if (keyword.trim() === "") {
      delete searchObject["keyword"];
    } else {
      searchObject["keyword"] = keyword.trim();
    }

    return searchObject;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const parsedObject = parseSearchInput(searchKeyWordInternshipLists);

      if (searchKeyWordInternshipLists) {
        dispatch(
          getHRInternshipListSearch({ parsedObject, column, columnDirection })
        );
      } else {
        dispatch(
          getHRInternshipListPagination({
            pageNo: currentInternshipPage,
            pageSize: 20,
            column,
            columnDirection,
          })
        );
      }
    }
  };

  const setClearKeyword = () => {
    setPositionApplied("");
    setSource("");
    setDateFrom("");
    setDateTo("");
    setSalaryRangeFrom(0);
    setSalaryRangeTo(0);
    setKeyword("");
    setStatusApplication("");

    dispatch(updateSearchInternshipList(""));
    dispatch(
      getHRInternshipListPagination({
        pageNo: currentInternshipPage,
        pageSize: 20,
        column,
        columnDirection,
      })
    );
  };

  useEffect(() => {
    if (showFilter) {
      const parsedObject = parseSearchInput(searchKeyWordInternshipLists);
      setPositionApplied(parsedObject.positionApplied);
      setSource(parsedObject.source);
      setDateFrom(parsedObject.dateFrom);
      setDateTo(parsedObject.dateTo);
      setSalaryRangeFrom(parsedObject.salaryFrom);
      setSalaryRangeTo(parsedObject.salaryTo);
      setKeyword(parsedObject.keyword);
      setStatusApplication(parsedObject.statusApplication);
    }

    // const handleClickOutside = (event) => {
    //   if (formRef.current && !formRef.current.contains(event.target)) {
    //     setShowFilter(false);
    //   }
    // };

    // if (showFilter) {
    //   document.addEventListener("mousedown", handleClickOutside);
    // } else {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // }

    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, [showFilter]);

  useEffect(() => {
    const fromLSsearchKeyWordInternshipLists = localStorage.getItem(
      searchKeyWordInternshipLists
    );
    if (fromLSsearchKeyWordInternshipLists) {
      dispatch(updateSearchInternshipList(fromLSsearchKeyWordInternshipLists));
    }

    if (!searchKeyWordInternshipLists) {
      dispatch(
        getHRInternshipListPagination({
          pageNo: currentInternshipPage,
          pageSize: 20,
          column,
          columnDirection,
        })
      );
    }
  }, [currentInternshipPage]);

  const handlePagination = (page) => {
    dispatch(updateCurrentInternshipPage(page));
  };

  const handleClickFilterSearch = () => {
    const parts = [];

    if (keyword) parts.push(`${keyword}`);
    if (positionApplied) parts.push(`positionApplied:${positionApplied}`);
    if (source) parts.push(`source:${source}`);
    if (dateFrom) parts.push(`dateFrom:${dateFrom}`);
    if (dateTo) parts.push(`dateTo:${dateTo}`);
    if (salaryRangeFrom) parts.push(`salaryFrom:${salaryRangeFrom}`);
    if (salaryRangeTo) parts.push(`salaryTo:${salaryRangeTo}`);
    if (statusApplication) parts.push(`statusApplication:${statusApplication}`);

    const parsedObject = parseSearchInput(parts.join(" "));
    dispatch(
      getHRInternshipListSearch({ parsedObject, column, columnDirection })
    );

    dispatch(updateSearchInternshipList(parts.join(" ")));

    setShowFilter(false);
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col>
          <InputGroup className="col-4 mb-3">
            <Form.Control
              className="form-control"
              placeholder="Search"
              onChange={({ currentTarget: input }) => {
                dispatch(updateSearchInternshipList(input.value));
              }}
              onFocus={() => setShowFilter(false)}
              onKeyDown={handleKeyDown}
              value={searchKeyWordInternshipLists}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setClearKeyword()}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>

            {showFilter && (
              <form
                ref={formRef}
                className="filter-form position-absolute top-100 start-0 w-50"
                style={{ zIndex: 9999 }}
              >
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="keyword"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Keyword
                    </label>
                    <input
                      type="text"
                      id="keyword"
                      value={keyword}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="dateRange"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      id="dateRange"
                      placeholder="From Date"
                      value={dateFrom}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                    <div style={{ width: "15px" }}></div>
                    <input
                      type="date"
                      id="dateRange"
                      placeholder="To Date"
                      value={dateTo}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-3 d-flex flex-row-reverse">
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleClickFilterSearch}
                  >
                    Search
                  </button>
                </div>
              </form>
            )}
          </InputGroup>
        </Col>
        <Col md="auto" sm="auto">
          {searchKeyWordInternshipLists ? (
            <PaginationList
              nPages={1}
              currentPage={1}
              handlePagination={handlePagination}
            />
          ) : (
            <PaginationList
              nPages={totalCurrentInternshipPage}
              currentPage={currentInternshipPage}
              handlePagination={handlePagination}
            />
          )}
        </Col>
      </Row>

      <TableInternshipList />

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faCircleCheck} color="#5E8ABB" />
          &nbsp; Approved by Supervisor
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faCircleXmark} color="#5E8ABB" />
          &nbsp; Rejected by Supervisor
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faUserSlash} color="#5E8ABB" />
          &nbsp; Interview Cancel
        </Col>
      </Row>

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <FontAwesomeIcon
            icon={faSquareFull}
            color="#DDF4FC"
            className="fa-2xl"
          />
          &nbsp; TBD
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon
            icon={faSquareFull}
            color="#fdb0aa"
            className="fa-2xl"
          />
          &nbsp; Internship Rejected
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faDownload} color="blue" />
          &nbsp; Download Biodata only
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faDownload} color="#E1AD01" />
          &nbsp; Download
        </Col>
      </Row>

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          {searchKeyWordInternshipLists ? (
            <PaginationList
              nPages={1}
              currentPage={1}
              handlePagination={handlePagination}
            />
          ) : (
            <PaginationList
              nPages={totalCurrentInternshipPage}
              currentPage={currentInternshipPage}
              handlePagination={handlePagination}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default InternshipList;
