import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Table,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglass,
  faEye,
  faCheck,
  faBox,
  faGraduationCap,
  faBoltLightning,
  faGlobe,
  faDownload,
  faCircleCheck,
  faUser,
  faNewspaper,
  faO,
  faPassport,
  faClose,
  faFilter,
  faSquareFull,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Select from "react-select";
import moment from "moment";
import { triggerBase64Download } from "common-base64-downloader-react";

import { styles } from "./HRTableListStyle";
import { PreActionList, FinalStatusList } from "../../2.Common/DropdownOptions";
import PaginationList from "../../2.Common/Pagination";
import LogoJobstock from "../../asset/Jobstock.png";
import LogoJobstreet from "../../asset/Jobstreet.png";
import LogoNonSarawakian from "../../asset/LogoNonSarawakian.png";

import {
  getHRApplicationListSearch,
  getHRApplicationListPagination,
  updateSearchApplicationList,
  updateCurrentPage,
  postExcelFileApplicationList,
} from "../../4.Store/HRTableListSlice";
import {
  putApplicationForm,
  getApplicationForm,
} from "../../4.Store/ApplicationFormSlice";

const ApplicationListOri = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [tick, setTick] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [positionApplied, setPositionApplied] = useState("");
  const [salaryRangeFrom, setSalaryRangeFrom] = useState(0);
  const [salaryRangeTo, setSalaryRangeTo] = useState(0);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [source, setSource] = useState("");
  const [statusApplication, setStatusApplication] = useState("");
  const [column, setColumn] = useState("date2");
  const [columnDirection, setColumnDirection] = useState(-1);

  const options = [
    { label: "", value: "" },
    { label: "HSL website", value: "HSL website" },
    { label: "Jobstock", value: "Jobstock" },
    { label: "Jobstreet", value: "Jobstreet" },
    { label: "LinkedIn", value: "LinkedIn" },
    { label: "FB", value: "FB" },
    { label: "Instagram", value: "Instagram" },
    { label: "Newspaper", value: "Newspaper" },
    { label: "Staff Referral", value: "Staff Referral" },
    { label: "Other", value: "Other" },
  ];

  const {
    lists,
    currentPage,
    totalCurrentPage,
    searchLists,
    searchKeyWordLists,
  } = useSelector((store) => store.HRTableList);

  const parseSearchInput = (searchText) => {
    const words = searchText.split(" ");
    const searchObject = {};

    let currentKey = "";
    let keyword = "";

    words.forEach((word) => {
      if (word.includes(":")) {
        const [key, value] = word.split(":");
        const cleanKey = key.trim();
        const cleanValue = value.trim();
        currentKey = cleanKey;
        searchObject[currentKey] = searchObject[currentKey]
          ? searchObject[currentKey] + " " + cleanValue
          : cleanValue;
      } else {
        if (currentKey === "") {
          keyword += word + " ";
        } else {
          searchObject[currentKey] += " " + word;
        }
      }
    });

    // Remove "keyword" key if it's empty
    if (keyword.trim() === "") {
      delete searchObject["keyword"];
    } else {
      searchObject["keyword"] = keyword.trim();
    }

    return searchObject;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const parsedObject = parseSearchInput(searchKeyWordLists);
      // setSearchObject(parsedObject);
      if (searchKeyWordLists) {
        dispatch(
          getHRApplicationListSearch({ parsedObject, column, columnDirection })
        );
      } else {
        dispatch(
          getHRApplicationListPagination({
            pageNo: currentPage,
            pageSize: 20,
            column,
            columnDirection,
          })
        );
      }
    }
  };

  const setClearKeyword = () => {
    setPositionApplied("");
    setSource("");
    setDateFrom("");
    setDateTo("");
    setSalaryRangeFrom(0);
    setSalaryRangeTo(0);
    setKeyword("");
    setStatusApplication("");

    dispatch(updateSearchApplicationList(""));
    dispatch(
      getHRApplicationListPagination({
        pageNo: currentPage,
        pageSize: 20,
        column,
        columnDirection,
      })
    );
  };

  useEffect(() => {
    if (showFilter) {
      const parsedObject = parseSearchInput(searchKeyWordLists);
      setPositionApplied(parsedObject.positionApplied);
      setSource(parsedObject.source);
      setDateFrom(parsedObject.dateFrom);
      setDateTo(parsedObject.dateTo);
      setSalaryRangeFrom(parsedObject.salaryFrom);
      setSalaryRangeTo(parsedObject.salaryTo);
      setKeyword(parsedObject.keyword);
      setStatusApplication(parsedObject.statusApplication);
    }
    // const handleClickOutside = (event) => {
    //   if (formRef.current && !formRef.current.contains(event.target)) {
    //     setShowFilter(false);
    //   }
    // };

    // if (showFilter) {
    //   document.addEventListener("mousedown", handleClickOutside);
    // } else {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // }

    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, [showFilter]);

  useEffect(() => {
    const fromLSsearchKeyWordLists = localStorage.getItem(searchKeyWordLists);
    if (fromLSsearchKeyWordLists) {
      dispatch(updateSearchApplicationList(fromLSsearchKeyWordLists));
    }

    if (!searchKeyWordLists) {
      dispatch(
        getHRApplicationListPagination({
          pageNo: currentPage,
          pageSize: 20,
          column,
          columnDirection,
        })
      );
    }
  }, [currentPage]);

  const { status, finalStatus } = useSelector((state) => state.ApplicationForm);

  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.isRed ? "brown" : "black", // Set color to red if the option is red, otherwise use black
    }),
  };

  const handlePagination = (page) => {
    dispatch(updateCurrentPage(page));
  };

  const handleClick = (_id) => {
    dispatch(
      putApplicationForm({
        id: _id,
        isRead: 1,
      })
    );
    navigate(`/HRViewForm/${_id}`);
    // console.log(_id);
  };

  const handleDropdownPreAction = (value) => {
    tick.forEach((item) => {
      const id = item;
      if (value?.value === 1) {
        dispatch(putApplicationForm({ id: id, status: 1 })).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
      if (value?.value === 30) {
        dispatch(putApplicationForm({ id: id, status: 30 })).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
      if (value?.value === 50) {
        dispatch(
          putApplicationForm({
            id: id,
            status: 50,
            dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
          })
        ).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
      if (value?.value === 100) {
        dispatch(
          putApplicationForm({
            id: id,
            status: 100,
            dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
          })
        ).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
      if (value?.value == 200) {
        dispatch(
          putApplicationForm({
            id: id,
            status: 200,
            dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
          })
        ).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
    });

    setTick([]);
  };

  const handleDropdownFinalStatus = (value) => {
    tick.forEach((item) => {
      const id = item;
      if (value?.value === "Hire") {
        dispatch(putApplicationForm({ id: id, finalStatus: "Hire" })).then(
          () => {
            dispatch(
              getHRApplicationListPagination({
                pageNo: currentPage,
                pageSize: 20,
              })
            );
          }
        );
      }
      if (value?.value === "KIV") {
        dispatch(putApplicationForm({ id: id, finalStatus: "KIV" })).then(
          () => {
            dispatch(
              getHRApplicationListPagination({
                pageNo: currentPage,
                pageSize: 20,
              })
            );
          }
        );
      }
      if (value?.value === "Not suitable") {
        dispatch(
          putApplicationForm({ id: id, finalStatus: "Not suitable" })
        ).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
      if (value?.value === "Reject IV") {
        dispatch(putApplicationForm({ id: id, finalStatus: "Reject IV" })).then(
          () => {
            dispatch(
              getHRApplicationListPagination({
                pageNo: currentPage,
                pageSize: 20,
              })
            );
          }
        );
      }
      if (value?.value === "Reject offer") {
        dispatch(
          putApplicationForm({ id: id, finalStatus: "Reject offer" })
        ).then(() => {
          dispatch(
            getHRApplicationListPagination({
              pageNo: currentPage,
              pageSize: 20,
            })
          );
        });
      }
      if (value?.value === "Blacklist") {
        dispatch(putApplicationForm({ id: id, finalStatus: "Blacklist" })).then(
          () => {
            dispatch(
              getHRApplicationListPagination({
                pageNo: currentPage,
                pageSize: 20,
              })
            );
          }
        );
      }
    });

    setTick([]);
  };

  const handleClickDownloadExcel = () => {
    dispatch(postExcelFileApplicationList()).then((res) => {
      const base64EXCEL =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        res.payload;
      triggerBase64Download(
        base64EXCEL,
        "Application List" + "_" + new Date().toISOString().slice(0, 10)
      );
    });
  };

  const handleTickCheckBox = (id, checked) => {
    let updatedTick;
    if (checked) {
      updatedTick = [...tick, id];
    } else {
      updatedTick = tick.filter((item) => item !== id); // Remove the id from the array
    }
    setTick(updatedTick);
    // console.log(updatedTick);
  };

  const handleDownloadApplicationForm = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/full/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/full/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;

      // Open a new tab linked to the PDF URL
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/basic/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${_id}`;
      const fileName = `${data?.payload?.sectionOne?.name} Application Form`;

      // Open a new tab linked to the PDF URL
      window.open(pdfUrl, "_blank");
    });
  };

  const handleClickFilterSearch = () => {
    const parts = [];

    if (keyword) parts.push(`${keyword}`);
    if (positionApplied) parts.push(`positionApplied:${positionApplied}`);
    if (source) parts.push(`source:${source}`);
    if (dateFrom) parts.push(`dateFrom:${dateFrom}`);
    if (dateTo) parts.push(`dateTo:${dateTo}`);
    if (salaryRangeFrom) parts.push(`salaryFrom:${salaryRangeFrom}`);
    if (salaryRangeTo) parts.push(`salaryTo:${salaryRangeTo}`);
    if (statusApplication) parts.push(`statusApplication:${statusApplication}`);

    const parsedObject = parseSearchInput(parts.join(" "));
    dispatch(
      getHRApplicationListSearch({ parsedObject, column, columnDirection })
    );

    dispatch(updateSearchApplicationList(parts.join(" ")));

    setShowFilter(false);
  };

  const formattedDate = (dateString) => {
    return dateString
      ? moment(dateString, "YYYY-MM-DD, h:mm:ss a").format("YYYY-MM-DD")
      : null;
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col>
          <InputGroup className="col-4 mb-3">
            <Form.Control
              className="form-control"
              placeholder="Search"
              onChange={({ currentTarget: input }) => {
                dispatch(updateSearchApplicationList(input.value));
              }}
              onFocus={() => setShowFilter(false)}
              onKeyDown={handleKeyDown}
              value={searchKeyWordLists}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setClearKeyword()}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>

            {showFilter && (
              <form
                ref={formRef}
                className="filter-form position-absolute top-100 start-0 w-50"
                style={{ zIndex: 9999 }}
              >
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="keyword"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Keyword
                    </label>
                    <input
                      type="text"
                      id="keyword"
                      value={keyword}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="position"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Position
                    </label>
                    <input
                      type="text"
                      id="position"
                      placeholder="Enter Position"
                      value={positionApplied}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setPositionApplied(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="source"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Source
                    </label>
                    <Form.Select
                      id="source"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                      className="border-0 border-bottom flex-grow-1"
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="dateRange"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      id="dateRange"
                      placeholder="From Date"
                      value={dateFrom}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                    <div style={{ width: "15px" }}></div>
                    <input
                      type="date"
                      id="dateRange"
                      placeholder="To Date"
                      value={dateTo}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="salaryRange"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Salary
                    </label>
                    <input
                      type="number"
                      id="salaryRange"
                      value={salaryRangeFrom}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setSalaryRangeFrom(e.target.value)}
                    />
                    <div style={{ width: "15px" }}></div>
                    <input
                      type="number"
                      id="salaryRange"
                      value={salaryRangeTo}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setSalaryRangeTo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="source"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Status
                    </label>
                    <Form.Select
                      value={statusApplication}
                      onChange={(e) => setStatusApplication(e.target.value)}
                      className="border-0 border-bottom flex-grow-1"
                    >
                      <option value=""></option>
                      <option value="PENDING">PENDING</option>
                      <option value="REVIEWONLY">REVIEW ONLY</option>
                      <option value="REVIEWANDRECOMMEND">
                        REVIEWED AND RECOMMENDED
                      </option>
                    </Form.Select>
                  </div>
                </div>

                <div className="mb-3 d-flex flex-row-reverse">
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleClickFilterSearch}
                  >
                    Search
                  </button>
                </div>
              </form>
            )}
          </InputGroup>
        </Col>
        <Col md="auto" sm="auto">
          <Select
            className="px-3"
            options={PreActionList}
            value={status}
            onChange={handleDropdownPreAction}
            styles={customStyles}
            placeholder="Pre Action"
          />
        </Col>
        <Col md="auto" sm="auto">
          <Select
            className="px-3"
            options={FinalStatusList}
            value={finalStatus}
            onChange={handleDropdownFinalStatus}
            placeholder="Final Status"
          />
        </Col>
        <Col md="auto" sm="auto">
          {searchKeyWordLists ? (
            <PaginationList
              nPages={1}
              currentPage={1}
              handlePagination={handlePagination}
            />
          ) : (
            <PaginationList
              nPages={totalCurrentPage}
              currentPage={currentPage}
              handlePagination={handlePagination}
            />
          )}
        </Col>
      </Row>

      <Table responsive>
        <thead style={styles.TableHeader}>
          <tr>
            <th style={styles.ThIcon}>
              <FontAwesomeIcon icon={faBox} />
            </th>
            <th style={styles.ThText}>No</th>
            <th style={styles.ThText}>Source</th>
            <th style={styles.ThText}>Date Applied</th>
            <th style={styles.ThText}>Date Action</th>
            <th style={styles.ThText}>Name</th>
            <th style={styles.ThText}>Position</th>
            <th style={styles.ThText}>NRIC</th>
            <th style={styles.ThIcon}>Age</th>
            <th style={styles.ThIcon}>
              <FontAwesomeIcon icon={faGraduationCap} />
            </th>
            <th style={styles.ThText}>Fresh Grad.</th>
            <th style={styles.ThText}>Location</th>
            <th style={styles.ThText}>RM</th>
            <th style={styles.ThIcon}>Pre Action</th>
            <th style={styles.ThIcon}>Final Status</th>
            <th style={styles.ThIcon}>
              <FontAwesomeIcon icon={faBoltLightning} />
            </th>
          </tr>
        </thead>
        <tbody>
          {lists.map(
            (
              {
                _id,
                sectionOne,
                date2,
                status,
                hrRemark,
                nonSarawakian,
                foreigner,
                isUpdatedByCandidate,
                finalStatus,
                dateUpdatedStatus,
              },
              i
            ) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    status == 100 || status == 200 ? "#ffffff" : "#DDF4FC",
                }}
              >
                <td style={styles.TdIcon}>
                  <Form.Check
                    type="checkbox"
                    checked={tick.includes(_id)}
                    onClick={(e) => handleTickCheckBox(_id, e.target.checked)}
                  />
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {i + 1 + indexOfFirstRecord}
                </td>
                <td style={styles.TdIcon} onClick={() => handleClick(_id)}>
                  {sectionOne?.ads_source?.value == "HSL website" && (
                    <FontAwesomeIcon icon={faGlobe} title="HSL website" />
                  )}
                  {sectionOne?.ads_source?.value == "Jobstock" && (
                    <Image
                      src={LogoJobstock}
                      className="sourceImg"
                      title="Jobstock"
                    />
                  )}
                  {sectionOne?.ads_source?.value == "Jobstreet" && (
                    <Image
                      src={LogoJobstreet}
                      className="sourceImg"
                      title="Jobstreet"
                    />
                  )}
                  {sectionOne?.ads_source?.value == "LinkedIn" && (
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      title="LinkedIn"
                      color="#0077B5"
                    />
                  )}
                  {sectionOne?.ads_source?.value == "FB" && (
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      title="FB"
                      color="#0866FF"
                    />
                  )}
                  {sectionOne?.ads_source?.value == "Instagram" && (
                    <FontAwesomeIcon
                      icon={faInstagram}
                      title="Instagram"
                      color="#644BC6"
                    />
                  )}
                  {sectionOne?.ads_source?.value == "Newspaper" && (
                    <FontAwesomeIcon icon={faNewspaper} title="Newspaper" />
                  )}
                  {sectionOne?.ads_source?.value == "Staff Referral" && (
                    <FontAwesomeIcon icon={faUser} title="Staff Referral" />
                  )}
                  {sectionOne?.ads_source?.value == "Other" && (
                    <FontAwesomeIcon icon={faO} title="Other" />
                  )}
                  {!sectionOne?.ads_source?.value && <span>N/A</span>}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {date2}
                </td>
                <td style={styles.TdText}>
                  {formattedDate(dateUpdatedStatus)}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.name?.toUpperCase()}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.position_applied?.label ||
                    sectionOne?.position_applied?.toUpperCase()}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.identification_certification}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.age}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.highest_qualification?.value == "SPM" && "SPM"}
                  {sectionOne?.highest_qualification?.value ==
                    "STPM/Matriculation/Foundation/STAM" && "STPM"}
                  {sectionOne?.highest_qualification?.value == "Certificates" &&
                    "CERT"}
                  {sectionOne?.highest_qualification?.value == "Diploma" &&
                    "DIP"}
                  {sectionOne?.highest_qualification?.value == "Degree" &&
                    "DEG"}
                  {sectionOne?.highest_qualification?.value == "Master" &&
                    "MAS"}
                  {sectionOne?.highest_qualification?.value == "PHD" && "PHD"}
                  {sectionOne?.highest_qualification?.value == "Others" &&
                    "OTH"}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.fresh_graduate?.value == "Yes"
                    ? sectionOne?.fresh_graduate?.label
                    : sectionOne?.fresh_graduate_no}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.current_location?.label?.toUpperCase()}
                </td>
                <td style={styles.TdText} onClick={() => handleClick(_id)}>
                  {sectionOne?.expected_salary}
                </td>
                <td style={styles.TdIcon}>
                  {status == 0 && (
                    <FontAwesomeIcon
                      type="button"
                      onClick={() => handleClick(_id)}
                      icon={faHourglass}
                      title="Pending Review"
                      color="red"
                    />
                  )}
                  {status == 100 && (
                    <FontAwesomeIcon
                      type="button"
                      onClick={() => handleClick(_id)}
                      icon={faEye}
                      title="Review Only"
                      color="black"
                    />
                  )}
                  {status == 200 && (
                    <FontAwesomeIcon
                      type="button"
                      onClick={() => handleClick(_id)}
                      icon={faCheck}
                      title="Review & Recommend"
                      color="green"
                    />
                  )}
                </td>
                <td style={styles.TdIcon}>{finalStatus && finalStatus}</td>
                <td className="text-nowrap" style={{ textAlign: "left" }}>
                  <span
                    style={{
                      ...styles.TdIconStyle,
                      display: isUpdatedByCandidate === 1 ? "inline" : "none",
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => handleDownloadApplicationForm(_id)}
                      icon={faDownload}
                      title="Print Form"
                      color="#E1AD01"
                    />
                  </span>
                  <span
                    style={{
                      ...styles.TdIconStyle,
                      display: isUpdatedByCandidate != 1 ? "inline" : "none",
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => handleDownloadBiodata(_id)}
                      icon={faDownload}
                      title="Print Biodata"
                      color="blue"
                    />
                  </span>
                  {hrRemark && (
                    <span style={{ marginLeft: "0.5rem" }}>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        color="blue"
                        title="HR Remark Exist"
                      />
                    </span>
                  )}
                  {nonSarawakian == "Yes" && (
                    <span style={{ marginLeft: "0.5rem" }}>
                      <Image
                        src={LogoNonSarawakian}
                        style={{ width: "20px", height: "20px" }}
                        title="NON SARAWAKIAN"
                      />
                    </span>
                  )}
                  {foreigner == "Yes" && (
                    <span style={{ marginLeft: "0.5rem" }}>
                      <FontAwesomeIcon
                        icon={faPassport}
                        title="FOREIGNER"
                        color="#9F1A1B"
                      />
                    </span>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faGlobe} />
          &nbsp;
          <Form.Label>HSL website</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <Image src={LogoJobstock} className="sourceImg" />
          &nbsp;
          <Form.Label>Jobstock</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <Image src={LogoJobstreet} className="sourceImg" />
          &nbsp;
          <Form.Label>Jobstreet</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faLinkedinIn} color="#0077B5" />
          &nbsp;
          <Form.Label>LinkedIn</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faFacebookF} color="#0866FF" />
          &nbsp;
          <Form.Label>FB</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faInstagram} color="#644BC6" />
          &nbsp;
          <Form.Label>Instagram</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faNewspaper} />
          &nbsp;
          <Form.Label>Newspaper</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faUser} title="Staff Referral" />
          &nbsp;
          <Form.Label>Staff Referral</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faO} title="Other" />
          &nbsp;
          <Form.Label>Other</Form.Label>
        </Col>
      </Row>

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <FontAwesomeIcon
            icon={faSquareFull}
            color="#DDF4FC"
            className="fa-2xl"
          />
          &nbsp; TBD
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faHourglass} color="red" />
          &nbsp;
          <Form.Label>Pending Review</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faEye} color="black" />
          &nbsp;
          <Form.Label>Review Only</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faCheck} color="green" />
          &nbsp;
          <Form.Label>Review & Recommend</Form.Label>
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faDownload} color="blue" />
          &nbsp; Download Biodata Only
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faDownload} color="#E1AD01" />
          &nbsp; Download
        </Col>
      </Row>

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <Select
            className="px-3"
            options={PreActionList}
            value={status}
            onChange={handleDropdownPreAction}
            styles={customStyles}
            placeholder="Pre Action"
          />
        </Col>
        <Col md="auto" sm="auto">
          <Select
            className="px-3"
            options={FinalStatusList}
            value={finalStatus}
            onChange={handleDropdownFinalStatus}
            placeholder="Final Status"
          />
        </Col>
        <Col md="auto" sm="auto">
          <Button
            onClick={handleClickDownloadExcel}
            variant="outline-primary"
            className="mx-3"
          >
            Download Excel File
          </Button>
        </Col>
        <Col md="auto" sm="auto">
          {searchKeyWordLists ? (
            <PaginationList
              nPages={1}
              currentPage={1}
              handlePagination={handlePagination}
            />
          ) : (
            <PaginationList
              nPages={totalCurrentPage}
              currentPage={currentPage}
              handlePagination={handlePagination}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default ApplicationListOri;
