import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { Card } from "react-bootstrap";

import { ProtectedRoute, LoginProtectedRoute } from "./5.Services/UserService";

import ApplicationFormFullSite from "./3.Sites/ApplicationFormFullSite";
import ApplicationFormSite from "./3.Sites/ApplicationFormSite";
import AssessmentFormSite from "./3.Sites/AssessmentFormSite";
import Homepage from "./3.Sites/Homepage";
import HRFormViewSite from "./3.Sites/HRFormViewSite";
import HRTableListSite from "./3.Sites/HRTableListSite";
import InternshipFormSite from "./3.Sites/InternshipFormSite";
import SVFormViewSite from "./3.Sites/SVFormViewSite";
import SVTableListSite from "./3.Sites/SVTableListSite";
import ArchiveList from "./1.Pages/HRTableList/ArchiveList";
import KIVList from "./1.Pages/HRTableList/KIVList";
import DuplicateListNew from "./1.Pages/HRTableList/DuplicateList";
import Email from "./1.Pages/EmailLogs/Email";
import AdvertisementSourceReport from "./1.Pages/AdvertisementSourceReport/AdvertisementSourceReport";

function App() {
  return (
    <>
      <Router basename={"/"}>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <LoginProtectedRoute roles={["jobapp_hr", "jobapp_sv"]}>
                  <Homepage />
                </LoginProtectedRoute>
              }
            />
            <Route
              path="/Homepage"
              element={
                <ProtectedRoute roles={["default-roles-hsltower"]}>
                  <Homepage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/HRView"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <HRTableListSite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SVView"
              element={
                <ProtectedRoute roles={["jobapp_sv"]}>
                  <SVTableListSite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ArchiveList"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <ArchiveList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/KIVList"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <KIVList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/DuplicateList"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <DuplicateListNew />
                </ProtectedRoute>
              }
            />
            <Route
              path="/log/email/:_id"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <Email />
                </ProtectedRoute>
              }
            />
            <Route path="/ApplicationForm" element={<ApplicationFormSite />} />
            <Route
              path="/ApplicationForm/:_id"
              element={<ApplicationFormSite />}
            />
            <Route
              path="/FullApplicationForm"
              element={<ApplicationFormFullSite />}
            />
            <Route path="/InternshipForm" element={<InternshipFormSite />} />
            <Route
              path="/InternshipForm/:_id"
              element={<InternshipFormSite />}
            />
            <Route
              path="/HRViewForm/:_id"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <HRFormViewSite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SVViewForm/:_id"
              element={
                <ProtectedRoute roles={["jobapp_sv"]}>
                  <SVFormViewSite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/AssessmentForm"
              element={
                <ProtectedRoute roles={["default-roles-hsltower"]}>
                  <AssessmentFormSite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/AssessmentForm/:_id"
              element={
                <ProtectedRoute roles={["default-roles-hsltower"]}>
                  <AssessmentFormSite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SourceReport"
              element={
                <ProtectedRoute roles={["jobapp_hr"]}>
                  <AdvertisementSourceReport />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        <Card.Footer className="text-muted" style={{ textAlign: "center" }}>
          Version 4 : 5 August 2024
        </Card.Footer>
      </Router>
    </>
  );
}

export default App;
