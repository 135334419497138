import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Container,
  Tab,
  Tabs,
  Row,
  Col,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "react-bootstrap";

import { styles } from "../1.Pages/AssessmentForm/AssessmentFormStyle";
import NavbarMenu from "../2.Common/NavbarMenu";
import SectionOne from "../1.Pages/ApplicationForm/SectionOne";
import ApplicationFormViewSite from "./ApplicationFormViewSite";
import AssessmentForm from "../1.Pages/AssessmentForm/AssessmentForm";

import {
  getAssessmentForm,
  putAssessmentForm,
} from "../4.Store/AssessmentSlice";
import {
  getApplicationForm,
  updateCurrentTab,
} from "../4.Store/ApplicationFormSlice";

const AssessmentFormSite = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    dispatch(getAssessmentForm(param._id)).then((data) => {
      // console.log(data);
      dispatch(getApplicationForm(data.payload.jobAppId));
    });
  }, [param._id]);

  const { currentTab, sectionOne } = useSelector(
    (state) => state.ApplicationForm
  );
  const {
    _id,
    jobAppId,
    sectionA,
    sectionB,
    sectionC,
    sectionD,
    approval,
    interviewer,
  } = useSelector((state) => state.Assessment);

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);
  const isHR = token?.realm_access?.roles?.includes("jobapp_hr");
  const isSV = token.realm_access?.roles?.includes("jobapp_sv");

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickHome = () => {
    navigate("/Homepage");
  };

  const handleClickUpdateAssessment = (ev) => {
    ev.preventDefault();
    const form = ev.currentTarget;

    if (form.checkValidity() === false) {
      ev.stopPropagation();
      ev.preventDefault();
    }

    setValidated(true);

    const entity = {
      _id: _id,

      jobAppId,
      sectionA,
      sectionB,
      sectionC,
      sectionD,
      approval,
      interviewer,
    };

    if (form.checkValidity()) {
      dispatch(putAssessmentForm(entity))
        .then(() => {
          Swal.fire("Successfully Submitted");
        })
        .then(() => {
          navigate(-1);
        })
        .catch((err) => {
          Swal.fire("Error / Masalah", err.response.data, "error", "OK");
        });
    }
  };

  return (
    <>
      {isHR && isSV && (
        <>
          <NavbarMenu />
          <nav aria-label="breadcrumb" class="px-1">
            <ol class="breadcrumb">
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/Homepage" className="breadcrumbstyle">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item breadcrumb-item">
                <a href={isHR ? "#/HRView" : "#/SVView"} className="breadcrumbstyle">
                  Main List
                </a>
              </li>
            </ol>
          </nav>
          <Container fluid>
            <Card style={styles.CardContainer}>
              {sectionOne?.place_of_birth === "" ? (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Detail">
                    <SectionOne />
                  </Tab>
                  <Tab eventKey="1" title="Assessment Form">
                    <AssessmentForm />
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Detail">
                    <ApplicationFormViewSite />
                  </Tab>
                  <Tab eventKey="1" title="Assessment Form">
                    <AssessmentForm />
                  </Tab>
                </Tabs>
              )}

              <Row className="mx-3">
                <Col>
                  {sectionOne?.place_of_birth ? (
                    currentTab == 0 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button variant="primary" onClick={handleClickBack}>
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab - 1))
                            }
                          >
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button variant="primary" onClick={handleClickBack}>
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab - 1))
                          }
                        >
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  )}

                  {sectionOne?.place_of_birth ? (
                    currentTab == 1 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={handleClickUpdateAssessment}
                          >
                            Submit Assessment
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab + 1))
                            }
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab + 1))
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={handleClickUpdateAssessment}
                        >
                          Submit Assessment
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      )}

      {!isHR && (
        <>
          <NavbarMenu />
          <nav aria-label="breadcrumb" class="px-1">
            <ol class="breadcrumb">
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/Homepage" className="breadcrumbstyle">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/SVView" className="breadcrumbstyle">
                  Main List
                </a>
              </li>
            </ol>
          </nav>
          <Container fluid>
            <Card style={styles.CardContainer}>
              {sectionOne?.place_of_birth === "" ? (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Detail">
                    <SectionOne />
                  </Tab>
                  <Tab eventKey="1" title="Assessment Form">
                    <AssessmentForm />
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Detail">
                    <ApplicationFormViewSite />
                  </Tab>
                  <Tab eventKey="1" title="Assessment Form">
                    <AssessmentForm />
                  </Tab>
                </Tabs>
              )}

              <Row className="mx-3">
                <Col>
                  {sectionOne?.place_of_birth ? (
                    currentTab == 0 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button variant="primary" onClick={handleClickBack}>
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab - 1))
                            }
                          >
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button variant="primary" onClick={handleClickBack}>
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab - 1))
                          }
                        >
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  )}

                  {sectionOne?.place_of_birth ? (
                    currentTab == 1 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={handleClickUpdateAssessment}
                          >
                            Submit Assessment
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab + 1))
                            }
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab + 1))
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={handleClickUpdateAssessment}
                        >
                          Submit Assessment
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      )}

      {!isSV && (
        <>
          <NavbarMenu />
          <nav aria-label="breadcrumb" class="px-1">
            <ol class="breadcrumb">
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/Homepage" className="breadcrumbstyle">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item breadcrumb-item">
                <a href="#/HRView" className="breadcrumbstyle">
                  Main List
                </a>
              </li>
            </ol>
          </nav>
          <Container fluid>
            <Card style={styles.CardContainer}>
              {sectionOne?.place_of_birth === "" ? (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Detail">
                    <SectionOne />
                  </Tab>
                  <Tab eventKey="1" title="Assessment Form">
                    <AssessmentForm />
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Candidate Detail">
                    <ApplicationFormViewSite />
                  </Tab>
                  <Tab eventKey="1" title="Assessment Form">
                    <AssessmentForm />
                  </Tab>
                </Tabs>
              )}

              <Row className="mx-3">
                <Col>
                  {sectionOne?.place_of_birth ? (
                    currentTab == 0 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button variant="primary" onClick={handleClickBack}>
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab - 1))
                            }
                          >
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button variant="primary" onClick={handleClickBack}>
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab - 1))
                          }
                        >
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  )}

                  {sectionOne?.place_of_birth ? (
                    currentTab == 1 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={handleClickUpdateAssessment}
                          >
                            Submit Assessment
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab + 1))
                            }
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab + 1))
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={handleClickUpdateAssessment}
                        >
                          Submit Assessment
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};
export default AssessmentFormSite;
